
interface tag {
  tag: string;
  background: string;
  color: string;
}

interface post {
  title: string;
  spoiler: string;
  tags: Array<tag>;
  href: string;
}

export const POSTS: Array<post> = [
  {
    title: "Buenas prácticas en procesos de selección",
    subtitle: "RaaP #9",
    publication_date: "09 Octubre 2021",
    spoiler:
      "Como bien sabéis, llevo muchos años trabajando en la industria del desarrollo de software, toda mi carrera profesional, ni mas ni menos...",
    href: "https://leonardopoza.substack.com/p/buenas-practicas-en-procesos-de-seleccion",
    tags: [{ tag: "Recruiting", color: "yellow" }],
  },
  {
    title: "¿Realmente necesito contratar un Fullstack Developer?",
    subtitle: "RaaP #10",
    publication_date: "09 Junio 2024",
    spoiler:
      "Considero que es MUY complicado encontrar un Fullstack completo. Ser efectivo, solido y autónomo en todas los ámbitos de los productos digitales que se desarrollan hoy en día es MUY complicado.",
    href: "https://leonardopoza.substack.com/p/realmente-necesito-contratar-un-fullstack",
    tags: [{ tag: "Recruiting", color: "yellow" }],
  }
];

/*
export const POSTS: Array<post> = [
  {
    title: "9 motivos por los que se deja un trabajo en el sector tecnológico.",
    publication_date: "03 Mayo 2024",
    spoiler: "Nunca te has preguntado, ¿qué nos lleva a tomar la decisión de dejar nuestro puesto de trabajo en el sector Tech?",
    href: "/blog/motivos-por-los-que-dejar-tu-trabajo",
    tags: [ { tag: "Recruiting", color: "yellow" } ],
  },
  {
    title: "Pruebas técnicas en un proceso de selección",
    publication_date: "24 Octubre 2022",
    spoiler:
      "No vengo a plantear si una prueba técnica es necesaria o no durante un proceso de selección para un perfil técnico, si no quiero que hablemos del COMO...",
    href: "/blog/pruebas-tecnicas-procesos-seleccion",
    tags: [{ tag: "Recruiting", color: "yellow" }],
  },
  {
    title: "Buenas prácticas en procesos de selección",
    publication_date: "09 Octubre 2021",
    spoiler:
      "Como bien sabéis, llevo muchos años trabajando en la industria del desarrollo de software, toda mi carrera profesional, ni mas ni menos...",
    href: "/blog/buenas-practicas-procesos-seleccion",
    tags: [{ tag: "Recruiting", color: "yellow" }],
  },
  {
    title: "Luchar por el equipo",
    publication_date: "24 Diciembre 2020",
    spoiler:
      "El tenis es un deporte solitario. Puedes pensar que no lo es tanto, pero el mismo Andre Agassi, número uno de la ATP durante 101 semanas, lo describe así en sus memorias “Open”...",
    href: "/blog/luchar-por-el-equipo",
    tags: [{ tag: "Recruiting", color: "yellow" }],
  },
  {
    title: "Ciao Payvision, Bella!",
    publication_date: "27 Noviembre 2020",
    spoiler:
      "Dejo Payvision. Después de casi 13 años, un 35% de mi vida, dejo Payvision. Una decisión que me ha costado mucho tomar, pero cada día que pasa me doy cuenta de que es la más...",
    tags: [{ tag: "Equipo", color: "blue" }],
    href: "/blog/ciao-payvision-bella",
  },
  {
    title: "Tips and Tricks for your first IT interview!",
    publication_date: "13 Agosto 2020",
    spoiler:
      "Una serie de 'trips and tricks' para poder ir mejor preparado a tu entrevista de trabajo. ¿Qué debo saber antes de aplicar a una posición? ¿Dónde puedo encontrar información sobre la empresa? ¿Me darán feedback?",
    href: "/blog/tips-and-tricks-first-interview",
    tags: [{ tag: "Recruiting", color: "yellow" }],
  },
];
*/

