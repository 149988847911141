import './App.css';
import { Fragment, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { Landing } from './pages/landing';
import { Blog } from './pages/blog';
import { Taller } from './pages/taller';
import { LuchaPorElEquipo } from './posts/20230131-luchar-por-el-equipo';
import { BuenasPracticasProcesosSeleccion } from './posts/20221212-buenas-practicas-procesos-seleccion';
import { CiaoPayvisionBella } from './posts/20201127-ciao-payvision-bella';
import { TipsAndTricksForInterview } from './posts/20221010-tips-and-tricks-for-first-tech-interview';
import { PruebasTecnicasProcesosSeleccion } from './posts/20221024-pruebas-tecnicas-procesos-seleccion';
import { MotivosPorLosQueDejarTuTrabajo } from './posts/20200920-motivos-por-los-que-dejar-tu-trabajo';

import ReactGA from 'react-ga4';

ReactGA.initialize("G-8W7QX2476E");

export default function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
  }, []);

  return (
    <Fragment>
       <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/taller" element={<Taller />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/motivos-por-los-que-dejar-tu-trabajo" element={<MotivosPorLosQueDejarTuTrabajo />} />
        <Route path="/blog/luchar-por-el-equipo" element={<LuchaPorElEquipo />} />
        <Route path="/blog/ciao-payvision-bella" element={<CiaoPayvisionBella />} />
        <Route path="/blog/buenas-practicas-procesos-seleccion" element={<BuenasPracticasProcesosSeleccion />} />
        <Route path="/blog/tips-and-tricks-first-interview" element={<TipsAndTricksForInterview />} />
        <Route path="/blog/pruebas-tecnicas-procesos-seleccion" element={<PruebasTecnicasProcesosSeleccion />} />
      </Routes>
    </Fragment>  
  );
}