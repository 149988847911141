import { Fragment } from "react";

import {
  Box,
  Heading,
  Text,
  Stack,
  Badge,
  Button,
  SimpleGrid,
  Card,
  CardBody,
  HStack,
  Container,
  VStack
} from "@chakra-ui/react";

import { Footer } from "../components/footer";
import { WithSubnavigation } from "../components/navbar";
import { FaTwitter } from "react-icons/fa";
import { SiSubstack } from "react-icons/si";
import { MetaTags } from "react-meta-tags";

var post = {
  title: "Ciao Payvision, Bella!",
  publication_date: "27 de Noviembre de 2020",
  tag: "Equipo",
  substrack_link:
    "https://leonardopoza.substack.com/?utm_source=leonardopoza.com&utm_medium=web&utm_campaign=cia-payvision-bella",
  share_twitter:
    "https://twitter.com/intent/tweet?text=Ciao%20Payvision%20Bella&url=https://leonardopoza.com/blog/ciao-payvision-bella&via=leonardopoza&hashtags=Equipo",
  related_post_left: {
    title: "Luchar por el equipo",
    spoiler:
      "El tenis es un deporte solitario. Puedes pensar que no lo es tanto, pero el mismo Andre Agassi, número uno de la ATP durante 101 semanas, lo describe así en sus memorias “Open”...",
    href: "/blog/luchar-por-el-equipo",
  },
  related_post_right: {
    title: "Buenas prácticas en procesos de selección",
    spoiler:
      "Como bien sabéis, llevo muchos años trabajando en la industria del desarrollo de software, toda mi carrera profesional, ni mas ni menos...",
    href: "/blog/buenas-practicas-procesos-seleccion",
  },
};

export function CiaoPayvisionBella() {
  return (
    <Fragment>
      
      <MetaTags>
        <title>Ciao Payvision, Bella!</title>
        <meta name="description" content="Dejo Payvision. Después de casi 13 años, un 35% de mi vida, dejo Payvision. Una decisión que me ha costado mucho tomar, pero cada día que pasa me doy cuenta de que es la más..." />
        <meta property="og:title" content="Ciao Payvision, Bella!" />
        <meta property="og:description" content="Dejo Payvision. Después de casi 13 años, un 35% de mi vida, dejo Payvision. Una decisión que me ha costado mucho tomar, pero cada día que pasa me doy cuenta de que es la más..." />
        <meta property="og:image" content="/post-images/featured-ciao-payvision.png" />
        <meta property="twitter:title" content="Ciao Payvision, Bella!" />
        <meta property="twitter:description" content="Dejo Payvision. Después de casi 13 años, un 35% de mi vida, dejo Payvision. Una decisión que me ha costado mucho tomar, pero cada día que pasa me doy cuenta de que es la más..." />
        <meta property="twitter:image" content="/post-images/featured-ciao-payvision.png" />
      </MetaTags>

      <WithSubnavigation />

      <Heading
        className="post-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "10vh" }}
        marginBottom={{ base: "2vh", md: "5vh" }}
        fontSize={{ base: "9vh", md: "12vh" }}
        lineHeight={{ base: "90%", md: "100%" }}
      >
        {post.title}
      </Heading>

      <HStack
        spacing="24px"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Box>
          <Heading className="blog-date" fontSize={{ base: "2vh", md: "4vh" }}>
            {post.publication_date}
          </Heading>
        </Box>
        <Box>
          <Badge
            textTransform={"capitalize"}
            background={"#fcfcbf"}
            color={"#000000"}
            fontSize={"sm"}
            alignSelf={"center"}
            width={"auto"}
            pl={4}
            pr={4}
            pt={1}
            pb={1}
            rounded={"md"}
          >
            {post.tag}
          </Badge>
        </Box>
      </HStack>

      <Container backgroundColor={"#ffffff"} minW="100%">
        <Stack
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
          as={Box}
          textAlign={"left"}
          spacing={{ base: 5, md: 10 }}
          py={{ base: 20, md: 20 }}
        >
          <Text fontSize={"lg"} align={"justify"}>
            Dejo Payvision. Después de casi 13 años, un 35% de mi vida, dejo
            Payvision. Una decisión que me ha costado mucho tomar, pero cada día
            que pasa me doy cuenta de que es la más acertada. Payvision es una
            empresa maravillosa, volvería a fichar con los ojos cerrados. Suena
            paradójico leer esta última frase escrita por una persona que está
            dejando la empresa, pero es así. Payvision es sinónimo calidad, de
            empatía, de comprensión y de familia. Un departamento de desarrollo
            basado en las buenas prácticas, la calidad, donde puedes respirar y
            sentir el amor por el código. Un lugar donde fallar no se penaliza,
            donde la colaboración se premia y donde se pone en el centro a la
            persona. ¡Y ESTO MOLA!
          </Text>
          <Text fontSize={"lg"} align={"justify"}>
            13 años junto a grandísimos profesionales, desarrollando proyectos
            de todo tipo, ayudando en el crecimiento del departamento… todo esto
            me ha nutrido y formado como profesional. Pero necesito retos
            nuevos. Un reto bonito, una oportunidad de seguir creciendo, de
            aportar mi experiencia y de seguir trabajando en lo que me apasiona
            - Recruitment + Desarrollo de producto + Tecnología - Desde hace un
            par de años, tenía claro que si salía de Payvision seria para ir
            donde voy, por lo menos esa era mi ilusión.
          </Text>
          <Text fontSize={"lg"} align={"justify"}>
            Creo que una buena forma de cerrar este POST, es hacerlo de forma
            transparente. Así es como me despedí de mis compañeros el pasado 11
            de noviembre.
          </Text>
          <Text fontSize={"lg"} align={"justify"}>
            "Hola chicos, lo que vais a leer es algo que no esperabais y
            seguramente os sorprenda enormemente, pero leedlo con una sonrisa,
            ¿prometido?
          </Text>
          <Text fontSize={"lg"} align={"justify"}>
            Dejo Payvision. Es la decisión más dura que he tenido que tomar en
            mi vida profesional, pero ha llegado el momento de tomarla. Como
            podéis imaginar no es algo que he decidido la última semana, sino es
            algo que lleva en mi cabeza el último año. He pasado aquí el 35% de
            mi vida. Me casé estando en Payvision. He tenido dos hijos durante
            estos 13 años. Como suelo decir, mi relación con Chema y Adolfo es
            más larga que la relación con mi mujer. Éramos 4 por 2008, y ahora
            dejo 66 amigos.
          </Text>
          <Text fontSize={"lg"} align={"justify"}>
            <b>Pero… ¿Por qué?</b> Seguro que es lo que ahora mismo estaréis
            pensado (En la cabeza de Recu resonará un enorme FUCK!)
          </Text>
          <Text fontSize={"lg"} align={"justify"}>
            El motivo es sencillo, tengo que mirar adelante y pensar en mi
            carrera profesional. Ese es el principal motivo de que deje la
            empresa. Necesito conocer otras formas de trabajar, tener retos
            diferentes y volver a sentir que puedo entregar a la empresa el 100%
            de mis capacidades. Me conocéis, sabéis que no me gustan las
            falsedades y en este momento, siento que no aporto a la compañía lo
            que puedo aportar. Necesito volver a sentir la adrenalina de la
            tecnología.
          </Text>
          <Text fontSize={"lg"} align={"justify"}>
            Gracias a todos. He aprendido de vosotros una barbaridad. Podéis
            estar orgullosos los unos de los otros, porque tenéis unos
            compañeros brutales. Adolfo, Chema… vosotros ya sabéis lo que pienso
            de unos tipos que el día de mi boda metieron una bañera llena de
            hielo en mi habitación (la cual se inundó). Solo podía mataros o
            quereros. Y estáis vivos.
          </Text>
          <Text fontSize={"lg"} align={"justify"}>
            Quería que supierais esto hoy, con tiempo para que podamos charlar y
            que os pueda contar a todos los que queráis con más detalle mi
            futuro. Con un café o una cerveza (sabéis que soy más de esto
            último).
          </Text>
          <Text fontSize={"lg"} align={"justify"}>
            Os voy a echar de menos.
          </Text>
        </Stack>

        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={4}
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
        >
          <Box>
            <a href={post.substrack_link} target={"new"}>
              <Button
                width={"100%"}
                height={"50px"}
                backgroundColor={"#000000"}
                color={"#ffffff"}
                rounded={0}
                rightIcon={<SiSubstack />}
                fontWeight={600}
                textTransform="uppercase"
                _hover={{ backgroundColor: "#f10965" }}
              >
                suscribete
              </Button>
            </a>
          </Box>
          <Box>
            <a href={post.share_twitter} target={"new"}>
              <Button
                width={"100%"}
                height={"50px"}
                backgroundColor={"#000000"}
                color={"#ffffff"}
                rounded={0}
                rightIcon={<FaTwitter />}
                fontWeight={600}
                textTransform="uppercase"
                _hover={{ backgroundColor: "#f10965" }}
              >
                Share
              </Button>
            </a>
          </Box>
        
        </SimpleGrid>

        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 8, md: 10 }}
          marginTop={{ base: "100px", md: "100px" }}
          marginBottom={{ base: "220px", md: "220px" }}
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
        >
          <Stack spacing={4}>
            <Heading
              className="latest-post-heading"
              marginBottom={{ base: "15vw", md: "5vw" }}
              marginTop={{ base: "0vw", md: "3vw" }}
              fontSize={{ base: "10vh", md: "12vh" }}
              lineHeight={{ base: "80%", md: "100%" }}
            >
              Posts<br></br>similares<span style={{ color: "#f10965" }}>.</span>
            </Heading>
          </Stack>
          <VStack spacing={4} align="stretch">
            <a href={post.related_post_left.href} className="card-zoomer">
              <Card
                borderRadius="0"
                boxShadow={"xl"}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Stack>
                  <CardBody>
                    <Heading size="md">{post.related_post_left.title}</Heading>
                    <Text py="2">{post.related_post_left.spoiler}</Text>
                  </CardBody>
                </Stack>
              </Card>
            </a>
            <a href={post.related_post_right.href} className="card-zoomer">
              <Card
                borderRadius="0"
                boxShadow={"xl"}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Stack>
                  <CardBody>
                    <Heading size="md">{post.related_post_right.title}</Heading>

                    <Text py="2">{post.related_post_right.spoiler}</Text>
                  </CardBody>
                </Stack>
              </Card>
            </a>
          </VStack>
        </SimpleGrid>
      </Container>

      <Footer />
    </Fragment>
  );
}
