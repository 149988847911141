import { Fragment } from "react";
import { Footer } from "../components/footer";
import { Header } from "../components/header";
import { MyStory } from "../components/my-story";
import { WhatIdo } from "../components/what-I-do";
import { LatestPost } from "../components/latest-post";
import { WhereITalk } from "../components/where-I-Talk";
import { WithSubnavigation } from "../components/navbar";
import { WantToTalk } from "../components/want-to-talk";
import { ChangeDocumentTitle } from "../Utils/Utilities";
import { MetaTags } from "react-meta-tags";

export function Landing() {
  ChangeDocumentTitle("Leonardo Poza - Página Personal");
  return (
    <Fragment>
       <MetaTags>
        <title>Leonardo Poza</title>
        <meta name="description" content="He pasado más de 15 años desarrollando software y liderando equipos de desarrollo. También he vivido la experiencia de ser el responsable de selección de los perfiles tecnológicos de una fintech durante 2 años. Si ponemos todo eso en una coctelera, podéis imaginar las mil y una experiencias vividas." />
        <meta property="og:title" content="Leonardo Poza" />
        <meta property="og:description" content="He pasado más de 15 años desarrollando software y liderando equipos de desarrollo. También he vivido la experiencia de ser el responsable de selección de los perfiles tecnológicos de una fintech durante 2 años. Si ponemos todo eso en una coctelera, podéis imaginar las mil y una experiencias vividas." />
        <meta property="og:image" content="/favicon.png" />
        <meta property="twitter:title" content="Leonardo Poza" />
        <meta property="twitter:description" content="He pasado más de 15 años desarrollando software y liderando equipos de desarrollo. También he vivido la experiencia de ser el responsable de selección de los perfiles tecnológicos de una fintech durante 2 años. Si ponemos todo eso en una coctelera, podéis imaginar las mil y una experiencias vividas." />
        <meta property="twitter:image" content="/favicon.png" />
      </MetaTags>
      <WithSubnavigation />
      <Header />
      <MyStory />
      <WhatIdo />
      <LatestPost />
      <WhereITalk />
      <WantToTalk />
      <Footer />
    </Fragment>
  );
}
