import {
  Heading,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import { FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { SiSubstack } from "react-icons/si";

export function WantToTalk() {
  return (
    <>
      <Heading
        className="contact-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "13.5vh", md: "13.5vh" }}
        fontSize={{ base: "12vh", md: "16vh" }}
      >
        Want <br></br>to talk <span style={{ color: "#FFC43D" }}>?</span>
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 5 }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "10vw", md: "15vw" }}
        marginTop={{ base: "5vw", md: "5vw" }}
      >
        <Box w="100%" marginTop={{ base: "15vw", md: "5vw" }}>
          <Heading className="contact-me-item">
            <a
              href="https://twitter.com/leonardopoza"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <FaTwitter />
              Twitter
            </a>
          </Heading>
        </Box>
        <Box w="100%" marginTop={{ base: "15vw", md: "5vw" }}>
          <Heading className="contact-me-item">
            <a
              href="https://www.linkedin.com/in/leonardopoza/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <FaLinkedin />
              Linkedin
            </a>
          </Heading>
        </Box>
        <Box w="100%" marginTop={{ base: "15vw", md: "5vw" }}>
          <Heading className="contact-me-item">
            <a
              href="https://www.instagram.com/leonardo.poza"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <FaInstagram />
              Instagram
            </a>
          </Heading>
        </Box>
        <Box w="100%" marginTop={{ base: "15vw", md: "5vw" }}>
          <Heading className="contact-me-item">
            <a
              href="https://leonardopoza.substack.com/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <SiSubstack />
              Substack
            </a>
          </Heading>
        </Box>
        <Box w="100%" marginTop={{ base: "15vw", md: "5vw" }}>
          <Heading className="contact-me-item">
            <a
              href="mailto:yo@leonardopoza.com"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <MdOutlineMail />
              E-mail
            </a>
          </Heading>
        </Box>
      </SimpleGrid>
    </>
  );
}
