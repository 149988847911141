
interface tag {
  tag: string;
  background: string;
  color: string;
}

interface content {
  title: string;
  spoiler: string;
  tags: Array<tag>;
  href: string;
}

export const ACADEMY_CONTENT: Array<content> = [
  {
    title: "1. Actores en un proceso de selección",
    description: "Lorem fistrum se calle ustée va usté muy cargadoo la caidita está la cosa muy malar diodeno. Condemor llevame al sircoo caballo blanco caballo negroorl sexuarl.",
    tag: "",
    color_tag: ""
  },
  {
    title: "2. Actores en un proceso de selección",
    description: "Lorem fistrum se calle ustée va usté muy cargadoo la caidita está la cosa muy malar diodeno. Condemor llevame al sircoo caballo blanco caballo negroorl sexuarl.",
    tag: "",
    color_tag: ""
  },
  {
    title: "3. Actores en un proceso de selección",
    description: "Lorem fistrum se calle ustée va usté muy cargadoo la caidita está la cosa muy malar diodeno. Condemor llevame al sircoo caballo blanco caballo negroorl sexuarl.",
    tag: "Práctica",
    color_tag: "#f10965"
  },
  {
    title: "4. Actores en un proceso de selección",
    description: "Lorem fistrum se calle ustée va usté muy cargadoo la caidita está la cosa muy malar diodeno. Condemor llevame al sircoo caballo blanco caballo negroorl sexuarl.",
    tag: "",
    color_tag: ""
  },
  {
    title: "5. Actores en un proceso de selección",
    description: "Lorem fistrum se calle ustée va usté muy cargadoo la caidita está la cosa muy malar diodeno. Condemor llevame al sircoo caballo blanco caballo negroorl sexuarl.",
    tag: "",
    color_tag: ""
  },
  {
    title: "6. Actores en un proceso de selección",
    description: "Lorem fistrum se calle ustée va usté muy cargadoo la caidita está la cosa muy malar diodeno. Condemor llevame al sircoo caballo blanco caballo negroorl sexuarl.",
    tag: "",
    color_tag: ""
  },
  {
    title: "7. Actores en un proceso de selección",
    description: "Lorem fistrum se calle ustée va usté muy cargadoo la caidita está la cosa muy malar diodeno. Condemor llevame al sircoo caballo blanco caballo negroorl sexuarl.",
    tag: "Práctica",
    color_tag: "#f10965"
  },
  {
    title: "8. Actores en un proceso de selección",
    description: "Lorem fistrum se calle ustée va usté muy cargadoo la caidita está la cosa muy malar diodeno. Condemor llevame al sircoo caballo blanco caballo negroorl sexuarl.",
    tag: "",
    color_tag: ""
  },
];

