import {
  Heading,
  Text,
  Stack,
  SimpleGrid,
  VStack,
  Card,
  CardBody,
} from "@chakra-ui/react";

import { POSTS } from "../configuration/blog-post";

export function LatestPost() {
  return (
    <SimpleGrid
      columns={{ base: 1, md: 2 }}
      spacing={{ base: 8, md: 10 }}
      marginBottom={{ base: "220px", md: "220px" }}
      marginLeft={{ base: "5%", md: "20%" }}
      marginRight={{ base: "5%", md: "20%" }}
    >
      <Stack spacing={4}>
        <Heading
          className="latest-post-heading"
          marginBottom={{ base: "15vw", md: "5vw" }}
          marginTop={{ base: "0vw", md: "5vw" }}
          fontSize={{ base: "10vh", md: "12vh" }}
          lineHeight={{ base: "80%", md: "100%" }}
        >
          Últimos <br></br>posts<span style={{ color: "#f10965" }}>.</span>
        </Heading>
      </Stack>
      <VStack spacing={4} align="stretch">
        <a href={POSTS[0].href} className="card-zoomer">
          <Card
            borderRadius="0"
            boxShadow={"xl"}
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
          >
            <Stack>
              <CardBody>
                <Heading size="md">{POSTS[0].title}</Heading>
                <Text py="2" className="raap-subtitle">{POSTS[0].subtitle}</Text>
                <Text py="2">{POSTS[0].spoiler}</Text>
              </CardBody>
            </Stack>
          </Card>
        </a>
        <a href={POSTS[1].href} className="card-zoomer">
          <Card
            borderRadius="0"
            boxShadow={"xl"}
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
          >
            <Stack>
              <CardBody>
                <Heading size="md">{POSTS[1].title}</Heading>
                <Text py="2" className="raap-subtitle">{POSTS[1].subtitle}</Text>
                <Text py="2">{POSTS[1].spoiler}</Text>
              </CardBody>
            </Stack>
          </Card>
        </a>
      </VStack>
    </SimpleGrid>
  );
}
