import {
  Heading,
  Box,
  Text,
  Stack,
  Flex,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";

export function TheTeacher() {
  return (
    <Box className="my-history-box" marginTop={{ base: "0vw", md: "2vw" }}>
      &nbsp;
      <Heading
        className="history-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "15vw", md: "5vw" }}
        marginTop={{ base: "20vw", md: "5vw" }}
        fontSize={{ base: "8vh", md: "12vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        Formador<span style={{ color: "#f10965" }}>:</span>
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "20vh", md: "27vh" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Stack spacing={4}>
          <Text>Burgalés. Padre por duplicado. Feliz al cuadrado.</Text>
          <Text>
            He pasado más de 15 años desarrollando software y liderando equipos
            técnicos en una fintech internacional. Me responsabilicé del momento de <b> Hyper-Growth</b> de la
            empresa, consiguiendo <b>duplicar</b> un departamento técnico deslocalizado
            entre España y Holanda, manteniendo una cultura de ingeniería sana.
            El equipo pasó de unas 40 personas a cerca de las 80 en menos de 2
            años. Durante esa época generé planes de atracción de talento,
            procesos de contratación de calidad, fidelización de talento,
            creación de una ladder para fomentar el crecimiento profesional del
            equipo con un correcto nivelado salarial, onbording, offboarding… En
            definitiva, dos años preciosos y muy emocionantes. Os dejo mi perfil
            de {" "}
            <a
              href="https://www.linkedin.com/in/leonardopoza/"
              target={"_blank"}
              rel="noreferrer"
            >
              LinkedIn
            </a>{" "} por si queréis más detalle.
          </Text>

          <Text>
          Tras esa experiencia, me uní al equipo de {" "}
            <a
              href="https://www.getmanfred.com"
              target={"_blank"}
              rel="noreferrer"
            >
            Manfred
            </a>{" "}
            para ayudar a cambiar la forma en la que se realizan los procesos de
            selección para perfiles técnicos. En un principio como Recruiter,
            siempre responsabilizándome de que la calidad de los procesos fuera
            la adecuada y actualmente como responsable del equipo de selección.
          </Text>
          <Text>
            Si ponemos todo eso en una coctelera, podéis imaginar las mil y una
            experiencias vividas. 🤘
          </Text>
        </Stack>
        <Flex>
          <Image
            rounded={"xl"}
            boxSize={{ base: "100%", md: "80%" }}
            src="/leonardo-poza-full.png"
            alt="Leonardo Poza"
            position={"relative"}
            marginLeft={{ base: "1%", md: "20%" }}
            marginRight={{ base: "1%", md: "20%" }}
            marginTop={{ base: "1%", md: "2%" }}
          />
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
