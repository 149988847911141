import { Fragment } from "react";

import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  Badge,
  Button,
  SimpleGrid,
  Card,
  CardBody,
  HStack,
  VStack,
} from "@chakra-ui/react";

import { Footer } from "../components/footer";
import { WithSubnavigation } from "../components/navbar";
import { FaTwitter } from "react-icons/fa";
import { SiSubstack } from "react-icons/si";
import { MetaTags } from "react-meta-tags";

var post = {
  title: "Buenas prácticas en procesos de selección.",
  publication_date: "12 de Diciembre de 2022",
  tag: "Recruiting",
  substrack_link:
    "https://leonardopoza.substack.com/?utm_source=leonardopoza.com&utm_medium=web&utm_campaign=buenas_practicas_procesos_seleccion",
  share_twitter:
    "https://twitter.com/intent/tweet?text=Buenas%20prácticas%20en%20procesos%20de%20selección&url=https://leonardopoza.com/blog/buenas-practicas-procesos-seleccion&via=leonardopoza&hashtags=Recruiting",
  related_post_left: {
    title: "Ciao Payvision Bella",
    spoiler:
      "Dejo Payvision. Después de casi 13 años, un 35% de mi vida, dejo Payvision. Una decisión que me ha costado mucho tomar, pero cada día que pasa me doy cuenta de que es la más...",
    href: "/blog/ciao-payvision-bella",
  },
  related_post_right: {
    title: "Luchar por el equipo",
    spoiler:
      "El tenis es un deporte solitario. Puedes pensar que no lo es tanto, pero el mismo Andre Agassi, número uno de la ATP durante 101 semanas, lo describe así en sus memorias “Open”...",
    href: "/blog/luchar-por-el-equipo",
  },
};

export function BuenasPracticasProcesosSeleccion() {
  return (
    <Fragment>
      <MetaTags>
        <title>Buenas prácticas en procesos de selección.</title>
        <meta
          name="description"
          content="Como bien sabéis, llevo muchos años trabajando en la industria del desarrollo de software, toda mi carrera profesional, ni mas ni menos..."
        />
        <meta
          property="og:title"
          content="Buenas prácticas en procesos de selección."
        />
        <meta
          property="og:description"
          content="Como bien sabéis, llevo muchos años trabajando en la industria del desarrollo de software, toda mi carrera profesional, ni mas ni menos..."
        />
        <meta
          property="og:image"
          content="https://leonardopoza.com/post-images/featured-procesos-seleccion.png"
        />
        <meta
          name="twitter:title"
          content="Buenas prácticas en procesos de selección."
        />
        <meta
          name="twitter:description"
          content="Como bien sabéis, llevo muchos años trabajando en la industria del desarrollo de software, toda mi carrera profesional, ni mas ni menos..."
        />
        <meta
          name="twitter:image"
          content="https://leonardopoza.com/post-images/featured-procesos-seleccion.png"
        />
        <meta
          property="og:url"
          content="https://leonardopoza.com/blog/buenas-practicas-procesos-seleccion"
        />
        <meta
          name="twitter:card"
          content="Como bien sabéis, llevo muchos años trabajando en la industria del desarrollo de software, toda mi carrera profesional, ni mas ni menos..."
        />
        <meta name="twitter:creator" content="@leonardopoza" />
      </MetaTags>

      <WithSubnavigation />

      <Heading
        className="post-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "10vh" }}
        marginBottom={{ base: "2vh", md: "5vh" }}
        fontSize={{ base: "9vh", md: "12vh" }}
        lineHeight={{ base: "90%", md: "100%" }}
      >
        {post.title}
      </Heading>

      <HStack
        spacing="24px"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Box>
          <Heading className="blog-date" fontSize={{ base: "2vh", md: "4vh" }}>
            {post.publication_date}
          </Heading>
        </Box>
        <Box>
          <Badge
            textTransform={"capitalize"}
            background={"#fcfcbf"}
            color={"#000000"}
            fontSize={"sm"}
            alignSelf={"center"}
            width={"auto"}
            pl={4}
            pr={4}
            pt={1}
            pb={1}
            rounded={"md"}
          >
            {post.tag}
          </Badge>
        </Box>
      </HStack>

      <Container backgroundColor={"#ffffff"} minW="100%">
        <Stack
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
          as={Box}
          textAlign={"left"}
          spacing={{ base: 5, md: 10 }}
          py={{ base: 20, md: 20 }}
        >
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Como bien sabéis, llevo muchos años trabajando en la industria del
            desarrollo de software, toda mi carrera profesional, ni mas ni
            menos. Durante todo este tiempo he estado involucrado en muchos
            procesos de selección, con un sombrero distinto dependiendo del
            momento. En ocasiones como candidato, otras veces como entrevistador
            y en estos momentos como Recruiter, o Tech Scout como lo llamamos en{" "}
            <a href="https://getmanfred.com" target={"new"}>
              Manfred
            </a>{" "}
            :)
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            He vivido y "sufrido" procesos de selección de todo tipo, en los que
            he sido tratado bien, pero otros muchos en los que no ha sido así.
            Como candidato, me han ofrecido posiciones que nada tenían que ver
            con mi experiencia, he recibido propuestas sin ningún tipo de
            información y en ocasiones me he quedado esperando un feedback que
            jamás llego... Cuando comencé a participar en procesos como
            entrevistador, tuve claro que errores no quería cometer, necesitaba
            crear un entorno de confianza y tranquilidad donde la persona
            entrevistada pudiera estar a gusto para poder mostrar todo su
            potencial.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Cuando me responsabilice del crecimiento del departamento de
            desarrollo en mí última etapa en Payvision, sabia como lo quería
            hacer. Tenia muy claro que todo tenia qué enfocarse en las personas
            que estarían en los procesos y que todo debía de girar entorno a una
            cosa, la empatía.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Ahora, desde que trabajo en Manfred, tengo clara una cosa. La labor
            del Recruiter o Scout es fundamental en todos los procesos de
            selección. Tener un pasado ligado al mundo del desarrollo de
            software, es una gran ventaja, puesto que te permite comprender
            mejor a los clientes o personas que necesitan incorporar el talento
            a sus proyectos y aconsejarles en base a tu experiencia. Pero aporta
            un valor aun mayor a la hora de hablar con las personas que se
            inscriben a un proceso de selección, les comprendes, has estado en
            su situación y eso te permite empatizar con ellos y por lo tanto
            asesorarles mejor.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            El otro día publique un hilo en{" "}
            <a href="https://twitter.com/leonardopoza" target={"new"}>
              Twitter
            </a>{" "}
            , con el que pretendía dar mi opinión sobre las labores que un
            Recruiter debe hacer para dar valor tanto a empresas y candidatos.
          </Text>

          <center>
            <blockquote class="twitter-tweet">
              <p lang="es" dir="ltr">
                Un buen Tech Recruiter, no es aquel que se limita a encontrar
                las tecnologías correspondientes en un CV y pasa los perfiles
                encontrados al cliente o hiring manager... os cuento que valor
                debe aportar en un proceso de selección.Dentro hilo
              </p>
              &mdash; Leonardo Poza (@leonardopoza)
              <a href="https://twitter.com/leonardopoza/status/1445993840830296067?ref_src=twsrc%5Etfw">
                October 7, 2021
              </a>
            </blockquote>
          </center>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Un buen Tech Recruiter, no es aquel que se limita a encontrar las
            tecnologías correspondientes en un CV y pasa los perfiles
            encontrados al cliente o hiring manager... os cuento que valor debe
            aportar en un proceso de selección.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            1.- Es capaz de tener una conversación con el cliente, para poder
            entender las necesidades de la posición. Realizando las preguntas
            apropiadas, empatizando y aconsejando sobre el perfil buscado.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            2.- Si durante esa conversación podéis aconsejar al cliente sobre el
            proceso de selección que tenga definido, tratad de que sea lo más
            ágil posible y si el candidato puede presentar código propio, mejor
            que mejor.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            3.- Redactar una buena oferta, en la cual se muestre de una manera
            ordenada, los motivos por los cuales la posición es interesante. Y
            no hablo de si tienen una mesa de pin-pon o fruta en la oficina.
            Hablo de un reto tecnológico, unas responsabilidades lógicas para el
            puesto, etc.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            4.- Saber interpretar un CV es fundamental. No vale leer en
            diagonal, puesto que muchas veces los CV esconden oro entre líneas,
            que debemos encontrar. En caso de dudas, conversar con la persona...
            te puede sorprender con conocimientos que no están plasmados en el
            CV o LinkedIn
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            5.- Entrevistar no es sencillo. Lo primero se tiene que crear un
            ambiente de confianza para que la persona muestre su potencial.
            Empatizar con la persona es clave, tenemos que comprender sus
            aspiraciones y ayudar en la búsqueda de un empleo apropiado.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            6.- Nunca hay que forzar a una persona a entrar en un proceso de
            selección si no está convencida de que ese sea el sitio donde quiera
            ir y nosotros también lo consideremos. ¡JAMÁS! Cambiar de empleo, no
            es una decisión sencilla y te puede cambiar la vida.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            7.- En un proceso de selección pueden ocurrir muchas cosas y un buen
            Recruiter tiene que poder ayudar y dar valor tanto al cliente como
            al la persona interesada en la posición.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            8.- Viendo datos del{" "}
            <a
              href="https://drive.google.com/file/d/1e8qXQIEcsWoGV1bHUFfirOS66L552KaO/view"
              target={"new"}
            >
              estudio
            </a>{" "}
            que lanzamos en Manfred, el 47% de la gente encuestada está en
            procesos de selección 🤯, con lo cual posiblemente los candidatos
            que tengamos en proceso, estén en más de uno. Aconsejemos al
            candidato honestamente.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            9.- Las contraofertas están a la orden del día, es otra situación
            con las que el Recruiter va a tener que lidiar. Nuevamente,
            hagámoslo orientando en todo momento a la persona, desde la humildad
            y nuestra experiencia, para que tome la mejor decisión para su
            carrera profesional.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            10.- Pidamos al cliente o la empresa contratante un feedback
            constante sobre las personas que están en proceso y mantengamos
            informados siempre a todas las partes.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            11.- Si consideramos que una persona es válida para un puesto como
            para presentar su candidatura, luchemos por ella hasta el último
            momento.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            12.- Informemos regularmente del estado del proceso. Si tenemos
            alguna dificultad, identifiquemos los motivos, obtengamos datos y
            dialoguemos con el cliente. Iteremos al igual que hacemos para
            desarrollar un producto y aportemos soluciones en caso de
            necesitarlas.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            13.- Si llega la hora de realizar una oferta en firme, aconsejemos
            al cliente, nosotros hemos empatizado y conocemos a la persona a la
            que se va a ofertar. Igualmente, asesoremos a la persona ofertada.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            14.- Hagamos un seguimiento tanto con la empresa como con la persona
            contratada. No hay nada más satisfactorio que te digan ambas partes
            que están felices los unos con los otros. En ese momento, habremos
            dado valor.
          </Text>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Con este articulo, no tengo otro objetivo que tratar de evangelizar
            y promover las buenas practicas a la hora de realizar procesos de
            selección. Los recruiters, tenemos un gran peso para poder cambiar
            las cosas, para tratar de hacerlas bien y con ética.
          </Text>
        </Stack>

        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={4}
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
        >
          <Box>
            <a href={post.substrack_link} target={"new"}>
              <Button
                width={"100%"}
                height={"50px"}
                backgroundColor={"#000000"}
                color={"#ffffff"}
                rounded={0}
                rightIcon={<SiSubstack />}
                fontWeight={600}
                textTransform="uppercase"
                _hover={{ backgroundColor: "#f10965" }}
              >
                suscribete
              </Button>
            </a>
          </Box>
          <Box>
            <a href={post.share_twitter} target={"new"}>
              <Button
                width={"100%"}
                height={"50px"}
                backgroundColor={"#000000"}
                color={"#ffffff"}
                rounded={0}
                rightIcon={<FaTwitter />}
                fontWeight={600}
                textTransform="uppercase"
                _hover={{ backgroundColor: "#f10965" }}
              >
                Share
              </Button>
            </a>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 8, md: 10 }}
          marginTop={{ base: "100px", md: "100px" }}
          marginBottom={{ base: "220px", md: "220px" }}
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
        >
          <Stack spacing={4}>
            <Heading
              className="latest-post-heading"
              marginBottom={{ base: "15vw", md: "5vw" }}
              marginTop={{ base: "0vw", md: "3vw" }}
              fontSize={{ base: "10vh", md: "12vh" }}
              lineHeight={{ base: "80%", md: "100%" }}
            >
              Posts<br></br>similares<span style={{ color: "#f10965" }}>.</span>
            </Heading>
          </Stack>
          <VStack spacing={4} align="stretch">
            <a href={post.related_post_left.href} className="card-zoomer">
              <Card
                borderRadius="0"
                boxShadow={"xl"}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Stack>
                  <CardBody>
                    <Heading size="md">{post.related_post_left.title}</Heading>
                    <Text py="2">{post.related_post_left.spoiler}</Text>
                  </CardBody>
                </Stack>
              </Card>
            </a>
            <a href={post.related_post_right.href} className="card-zoomer">
              <Card
                borderRadius="0"
                boxShadow={"xl"}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Stack>
                  <CardBody>
                    <Heading size="md">{post.related_post_right.title}</Heading>

                    <Text py="2">{post.related_post_right.spoiler}</Text>
                  </CardBody>
                </Stack>
              </Card>
            </a>
          </VStack>
        </SimpleGrid>
      </Container>

      <Footer />
    </Fragment>
  );
}
