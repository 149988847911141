import {
  Heading,
  Text,
  Stack,
  SimpleGrid,
  Card,
  CardBody,
  Box,
} from "@chakra-ui/react";

export function AcademyValue() {
  return (
    <Box className="my-history-box" marginTop={{ base: "0vw", md: "2vw" }}>
      &nbsp;
      <Heading
        className="history-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "15vw", md: "5vw" }}
        fontSize={{ base: "8vh", md: "12vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        Qué te llevarás de la formación
        <span style={{ color: "#66C7F4" }}>?</span>
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 4 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "220px", md: "220px" }}
        marginLeft={{ base: "5%", md: "5%" }}
        marginRight={{ base: "5%", md: "5%" }}
      >
        <Stack spacing={4}>
          <Card
            borderRadius="6"
            boxShadow={"xl"}
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            minH={"280px"}
          >
            <CardBody>
              <Stack mt="6" spacing="3">
                <Heading size="md" className="benefits-heading">
                  Match! Mejora tu conversión.
                </Heading>
                <Text>
                  Entender la posición. Que el equipo de selección este alineado
                  con el hiring manager y el equipo técnico. Comprender a las
                  personas que entrevistas. Estas y muchas cosas más harán que
                  mejores tu conversión. Es mejor entrevistar a 3 personas
                  buenas, que a 10 que no terminan de encanjar.
                </Text>
              </Stack>
            </CardBody>
          </Card>
        </Stack>
        <Stack spacing={4}>
          <Card
            borderRadius="6"
            boxShadow={"xl"}
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            minH={"280px"}
          >
            <CardBody>
              <Stack mt="6" spacing="3">
                <Heading size="md" className="benefits-heading">
                  Reduce tiempos.
                </Heading>
                <Text>
                  Saber donde encontrar a las personas que encajan con la
                  posición buscada, en que comunidades, que eventos, que
                  plataformas… te hará ahorrar tiempo. Igualmente aprenderás a
                  automatizar procesos que te harán tener mas agilidad y
                  eficacia.
                </Text>
              </Stack>
            </CardBody>
          </Card>
        </Stack>
        <Stack spacing={4}>
          <Card
            borderRadius="6"
            boxShadow={"xl"}
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            minH={"280px"}
          >
            <CardBody>
              <Stack mt="6" spacing="3">
                <Heading size="md" className="benefits-heading">
                  Mide, itera y mejora.
                </Heading>
                <Text>
                  Aprenderás a monitorizar tus procesos de selección en todo
                  momento. Los datos son poder, tenerlos te ayudarán a mejorar
                  continuamente y cada vez tener un proceso más pulido y eficaz.
                </Text>
              </Stack>
            </CardBody>
          </Card>
        </Stack>
        <Stack spacing={4}>
          <Card
            borderRadius="6"
            boxShadow={"xl"}
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            minH={"280px"}
          >
            <CardBody>
              <Stack mt="6" spacing="3">
                <Heading size="md" className="benefits-heading">
                  Ahorra dinero! No pierdas el coste de oportunidad.
                </Heading>
                <Text>
                  El tiempo es dinero. Esta claro que hacer un proceso ágil te
                  hará ahorrar dinero. Pero te has preguntado ¿cual es el coste
                  de oportunidad para tu negocio si tardas 3 o 4 meses en
                  contratar a la persona apropiada?
                </Text>
              </Stack>
            </CardBody>
          </Card>
        </Stack>
      </SimpleGrid>
    </Box>
  );
}
