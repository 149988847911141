import {
  Box,
  chakra,
  Container,
  Heading,
  Stack,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react";
import { FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { ReactNode } from "react";

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode,
  label: string,
  href: string,
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("#1e283b", "#1e283b")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export function Footer() {
  return (
    <Box 
    boxShadow="dark-lg"
    bg={"#0f172a"}
    color={useColorModeValue("white")}>
      <Container  bg={"#0f172a"}
        as={Stack}
        maxW={"6xl"}
        minH="150px"
        height={"150px"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Heading className="footer-heading">© 2024 Leonardo Poza.</Heading>

        <Stack direction={"row"} spacing={6} bg={"#0f172a"}  color="#dcdcdc !important"
            className="contact-me-item"> 
          <SocialButton
            label={"Twitter"}
            href={"https://twitter.com/leonardopoza"}
          >
            <FaXTwitter />
          </SocialButton>
          <SocialButton
            label={"Linkedin"}
            href={"https://www.linkedin.com/in/leonardopoza/"}
          >
            <FaLinkedinIn />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  );
}
