import { Fragment } from "react";

import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  Badge,
  Button,
  SimpleGrid,
  Card,
  CardBody,
  HStack,
  VStack,
} from "@chakra-ui/react";

import { Footer } from "../components/footer";
import { WithSubnavigation } from "../components/navbar";
import { FaTwitter } from "react-icons/fa";
import { SiSubstack } from "react-icons/si";
import { MetaTags } from "react-meta-tags";

var post = {
  title: "Pruebas técnicas en un proceso de selección.",
  publication_date: "24 de Octubre de 2022",
  tag: "Recruiting",
  substrack_link:
    "https://leonardopoza.substack.com/?utm_source=leonardopoza.com&utm_medium=web&utm_campaign=pruebas_tecnicas_procesos_seleccion",
  share_twitter:
    "https://twitter.com/intent/tweet?text=Pruebas%20técnicas%20en%20procesos%20de%20selección&url=https://leonardopoza.com/blog/buenas-practicas-procesos-seleccion&via=leonardopoza&hashtags=Recruiting",
    related_post_left: {
      title: "Tips and Tricks for your first IT interview!",
      spoiler:
        "Una serie de 'trips and tricks' para poder ir mejor preparado a tu entrevista de trabajo. ¿Qué debo saber antes de aplicar a una posición? ¿Dónde puedo encontrar información sobre la empresa?",
      href: "/blog/tips-and-tricks-first-interview",
    },
    related_post_right: {
      title: "Buenas prácticas en procesos de selección",
      spoiler:
        "Como bien sabéis, llevo muchos años trabajando en la industria del desarrollo de software, toda mi carrera profesional, ni mas ni menos...",
      href: "/blog/buenas-practicas-procesos-seleccion",
    },
};

export function PruebasTecnicasProcesosSeleccion() {
  return (
    <Fragment>
      <MetaTags>
        <title>Pruebas técnicas en un proceso de selección.</title>
        <meta
          name="description"
          content="No vengo a plantear si una prueba técnica es necesaria o no durante un proceso de selección para un perfil técnico, si no quiero que hablemos del COMO..."
        />
        <meta
          property="og:title"
          content="Pruebas técnicas en un proceso de selección."
        />
        <meta
          property="og:description"
          content="No vengo a plantear si una prueba técnica es necesaria o no durante un proceso de selección para un perfil técnico, si no quiero que hablemos del COMO..."
        />
        <meta
          name="twitter:title"
          content="Pruebas técnicas en un proceso de selección."
        />
        <meta
          name="twitter:description"
          content="No vengo a plantear si una prueba técnica es necesaria o no durante un proceso de selección para un perfil técnico, si no quiero que hablemos del COMO..."
        />
        <meta
          property="og:url"
          content="https://leonardopoza.com/blog/pruebas-tecnicas-procesos-seleccion"
        />
        <meta
          name="twitter:card"
          content="No vengo a plantear si una prueba técnica es necesaria o no durante un proceso de selección para un perfil técnico, si no quiero que hablemos del COMO..."
        />
        <meta name="twitter:creator" content="@leonardopoza" />
      </MetaTags>

      <WithSubnavigation />

      <Heading
        className="post-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "10vh" }}
        marginBottom={{ base: "2vh", md: "5vh" }}
        fontSize={{ base: "9vh", md: "12vh" }}
        lineHeight={{ base: "90%", md: "100%" }}
      >
        {post.title}
      </Heading>

      <HStack
        spacing="24px"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Box>
          <Heading className="blog-date" fontSize={{ base: "2vh", md: "4vh" }}>
            {post.publication_date}
          </Heading>
        </Box>
        <Box>
          <Badge
            textTransform={"capitalize"}
            background={"#fcfcbf"}
            color={"#000000"}
            fontSize={"sm"}
            alignSelf={"center"}
            width={"auto"}
            pl={4}
            pr={4}
            pt={1}
            pb={1}
            rounded={"md"}
          >
            {post.tag}
          </Badge>
        </Box>
      </HStack>

      <Container backgroundColor={"#ffffff"} minW="100%">
        <Stack
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
          as={Box}
          textAlign={"left"}
          spacing={{ base: 5, md: 10 }}
          py={{ base: 20, md: 20 }}
        >
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
          No vengo a plantear si una prueba técnica es necesaria o no durante un proceso de selección para un perfil técnico, si no quiero que hablemos del COMO.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
          Creo que comprobar el nivel técnico antes de contratar a una persona es siempre necesario, de una u otra manera deberemos comprobar que lo que la persona dice saber es cierto, o no. Esto es algo aplicable a cualquier sector, no solo al tecnológico.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
          En la industria tecnológica y más en concreto en la del desarrollo de software existe un grandísimo debate al respecto. Gran parte de la comunidad esta cansada de hacer pruebas para cada uno de los procesos de selección en los que participa, algunos incluso se niegan a participar del proceso si tienen que realizar una prueba técnica más. Otra gran parte, la realiza, invierte tiempo y se lo toma como un aprendizaje que le vendrá bien para su carrera profesional. Esta claro que todo el mundo tiene su opinión formada al respecto, pero, ¿existe un tipo de prueba que satisfaga a todos los "bandos"? Yo al menos no tengo la respuesta pero sé cual es para mi la prueba "perfecta" pero es solo mi opinión (si aguantas hasta el final, te contaré cual es).
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Una cosa esta clara y es que toda prueba, debería cumplir unos mínimos:
          </Text>



          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            - Una prueba, siempre tiene que ser respetuosa con la persona que la realice. Obvio, ¿verdad?
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            - Que sea ajustada en el tiempo. Tengamos en cuenta que la persona que la realiza, lo hará en su tiempo personal.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            - Ser clara. Es decir, que siempre vaya acompañada de una buena explicación de qué se debe hacer, que mínimos tiene que cumplir y que sería estupendo encontrar a la hora de corregir la prueba.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            - Es bueno dejar espacio para la creatividad, es más, en ocasiones se busca dejar ese espacio a las personas que la resuelva, pero de ser así, debe estar claro en el enunciado.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            - Asegurar que valida las capacidades que estás pidiendo para el puesto ofertado.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            - Si tienes una prueba exigente, que cuando se incorpore a la empresa tenga el mismo reto intelectual en su día a día. No hay nada mas frustrante que te pidan saber mandar un satélite a la luna, para luego estar haciendo un trabajo rutinario.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            - Una vez realizada la prueba, se debería dar tiempo a la persona para defender la solución aportada con parte del equipo técnico. Escuchar los motivos por los que realizo la prueba de una forma u otra.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            - Ser respetuoso con quien realiza el reto y escuchar los motivos por los que realizo la prueba de una u otra forma.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            - La persona o personas que revisen la prueba, deberían todos haber realizado la prueba con anterioridad.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            - Una prueba de código, en mi opinión, debería ser sobre lo que girase una conversación con las personas que han corregido la prueba. Un punto de partida.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
          Todo esto lo resumí en un hilo de Twitter que lance la semana pasada y que considero que esta bien darle continuidad y terminad de dar mi opinión al respecto.
          </Text>
          <center>
            <blockquote class="twitter-tweet">
              <p lang="es" dir="ltr">
              Pruebas técnicas... no vengo a plantear si una prueba técnica es necesaria o no durante un proceso de selección para un perfil técnico, quiero que hablemos del COMO. Dentro hilo 🧵
              </p>
              &mdash; Leonardo Poza (@leonardopoza)
              <a href="https://twitter.com/leonardopoza/status/1449979174916132865?ref_src=twsrc%5Etfw">
                October 18, 2021
              </a>
            </blockquote>
          </center>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
          Esto no son más que buenas prácticas a la hora de diseñar una prueba técnica, una serie de mínimos que se deberían cumplir, al menos desde mi punto de vista. Pero, existen muchas formas de validar los conocimientos técnicos de una persona, unos más acertados, otros que a mí, me cuesta ver con buenos ojos.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            - Una prueba técnica corta, sin limite de tiempo, pero que se pueda resolver en 2 o 3 horas. Una Kata, dejando a la elección del consumidor el lenguaje de programación para resolverla. Con criterio y que busque identificar una serie de conocimientos.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            - Una prueba técnica larga, exhaustiva, que requiera una dedicación importante. Algún compañero de la comunidad, me ha llegado a comentar que ha tenido que estudiar para hacer alguna prueba. Solo se suelen ver en empresas donde la gente "desee" trabajar y estén dispuestos a invertir el esfuerzo para conseguir ese sueño. También tenemos que tener en cuenta, que para las pruebas de selección de ciertas empresas como pueden ser Amazon, Microsoft, Spotify, Facebook y Atlassian entre otras, si no te preparas las entrevistas, pocas opciones tienes.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            - Pruebas de live coding con alguna herramienta con la que te monitorizan. Suelen ser con un tiempo fijado y en la que alguien de la empresa, estará presenciando como codificas. A mí esta modalidad no me gusta, puesto que no genera un ambiente en el que la persona pueda mostrar toda su capacidad. Genera ansiedad y nerviosismo, algo que no solemos tener a la hora de trabajar... espero.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            - Muchos técnicos tenemos proyectos personales, código propio del que estemos orgullosos y podamos mostrar a la empresa que oferta el empleo. Yo creo que con ese código y una conversación técnica acerca de cómo se ha desarrollado, puede ser más que suficiente para validar los conocimiento de la persona. Ventaja; no requiere de una inversión en concreto para el proceso.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
          Hace poco, descubrí la que ha sido hasta el momento una de las mejores pruebas que he visto. La persona que estaba haciendo el proceso de selección no tenía que invertir tiempo en codificar. Se trataba de una entrevista con dos personas del equipo de desarrollo, las cuales mostraban código real de la empresa y pedían a la persona entrevistada que pensara como añadir una funcionalidad nueva al código. No era un live coding donde la persona se sentiría observada, si no un trabajo colaborativo con gente que si todo iba bien, serian compañeros de trabajo. Es decir, lo más parecido al día a día de un equipo de desarrollo. De esta forma, puedes validar conocimientos técnicos, capacidad de comunicar y escuchar, trabajo en equipo... muchos factores los cuales harán de la persona un buen compañero de batallas. Pero eso no es todo, no solo la empresa tiene que validar a la persona, si no que la persona también tiene que tener claro que es el sitio donde quiere trabajar. Durante la entrevista, se vera cual es la base de código con la que trabajará dicha persona y podrá hacer pair programming con los que serian sus compañeros... un ejercicio de transparencia llevado a una entrevista técnica.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
          En definitiva, lo importante no es si un proceso de selección tiene o no tiene prueba técnica, si no de COMO sea esta. Debemos de buscar la forma que sea ventajosa para ambas partes, empresa y persona entrevistada.
          </Text>
          
        </Stack>

        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={4}
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
        >
          <Box>
            <a href={post.substrack_link} target={"new"}>
              <Button
                width={"100%"}
                height={"50px"}
                backgroundColor={"#000000"}
                color={"#ffffff"}
                rounded={0}
                rightIcon={<SiSubstack />}
                fontWeight={600}
                textTransform="uppercase"
                _hover={{ backgroundColor: "#f10965" }}
              >
                suscribete
              </Button>
            </a>
          </Box>
          <Box>
            <a href={post.share_twitter} target={"new"}>
              <Button
                width={"100%"}
                height={"50px"}
                backgroundColor={"#000000"}
                color={"#ffffff"}
                rounded={0}
                rightIcon={<FaTwitter />}
                fontWeight={600}
                textTransform="uppercase"
                _hover={{ backgroundColor: "#f10965" }}
              >
                Share
              </Button>
            </a>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 8, md: 10 }}
          marginTop={{ base: "100px", md: "100px" }}
          marginBottom={{ base: "220px", md: "220px" }}
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
        >
          <Stack spacing={4}>
            <Heading
              className="latest-post-heading"
              marginBottom={{ base: "15vw", md: "5vw" }}
              marginTop={{ base: "0vw", md: "3vw" }}
              fontSize={{ base: "10vh", md: "12vh" }}
              lineHeight={{ base: "80%", md: "100%" }}
            >
              Posts<br></br>similares<span style={{ color: "#f10965" }}>.</span>
            </Heading>
          </Stack>
          <VStack spacing={4} align="stretch">
            <a href={post.related_post_left.href} className="card-zoomer">
              <Card
                borderRadius="0"
                boxShadow={"xl"}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Stack>
                  <CardBody>
                    <Heading size="md">{post.related_post_left.title}</Heading>
                    <Text py="2">{post.related_post_left.spoiler}</Text>
                  </CardBody>
                </Stack>
              </Card>
            </a>
            <a href={post.related_post_right.href} className="card-zoomer">
              <Card
                borderRadius="0"
                boxShadow={"xl"}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Stack>
                  <CardBody>
                    <Heading size="md">{post.related_post_right.title}</Heading>

                    <Text py="2">{post.related_post_right.spoiler}</Text>
                  </CardBody>
                </Stack>
              </Card>
            </a>
          </VStack>
        </SimpleGrid>
      </Container>

      <Footer />
    </Fragment>
  );
}
