import { Fragment } from "react";

import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  Badge,
  Button,
  SimpleGrid,
  Card,
  CardBody,
  HStack,
  VStack,
} from "@chakra-ui/react";

import { Footer } from "../components/footer";
import { WithSubnavigation } from "../components/navbar";
import { FaTwitter } from "react-icons/fa";
import { SiSubstack } from "react-icons/si";
import { MetaTags } from "react-meta-tags";

var post = {
  title: "9 motivos por los que se deja un trabajo en el sector tecnológico.",
  publication_date: "20 de Agosto de 2020",
  tag: "Recruiting",
  substrack_link:
    "https://leonardopoza.substack.com/?utm_source=leonardopoza.com&utm_medium=web&utm_campaign=motivos-cambio-empleo-sector-tecnologico",
  share_twitter:
    "https://twitter.com/intent/tweet?text=9%20motivos%20por%20los%20que%20dejar%20tu%20trabajo&url=https://leonardopoza.com/blog/motivos-por-los-que-dejar-tu-trabajo&via=leonardopoza&hashtags=Recruiting",
  related_post_left: {
    title: "Ciao Payvision Bella",
    spoiler:
      "Dejo Payvision. Después de casi 13 años, un 35% de mi vida, dejo Payvision. Una decisión que me ha costado mucho tomar, pero cada día que pasa me doy cuenta de que es la más...",
    href: "/blog/ciao-payvision-bella",
  },
  related_post_right: {
    title: "Luchar por el equipo",
    spoiler:
      "El tenis es un deporte solitario. Puedes pensar que no lo es tanto, pero el mismo Andre Agassi, número uno de la ATP durante 101 semanas, lo describe así en sus memorias “Open”...",
    href: "/blog/luchar-por-el-equipo",
  },
};

export function MotivosPorLosQueDejarTuTrabajo() {
  return (
    <Fragment>
      <MetaTags>
        <title>
          9 motivos por los que se deja un trabajo en el sector tecnológico.
        </title>
        <meta
          name="description"
          content="Nunca te has preguntado, ¿qué nos lleva a tomar la decisión de dejar nuestro puesto de trabajo en el sector Tech?"
        />
        <meta
          property="og:title"
          content="9 motivos por los que se deja un trabajo en el sector tecnológico."
        />
        <meta
          property="og:description"
          content="Nunca te has preguntado, ¿qué nos lleva a tomar la decisión de dejar nuestro puesto de trabajo en el sector Tech?"
        />
        <meta
          property="og:image"
          content="https://leonardopoza.com/post-images/featured-motivos-dejar-trabajo.png"
        />
        <meta
          name="twitter:title"
          content="9 motivos por los que se deja un trabajo en el sector tecnológico."
        />
        <meta
          name="twitter:description"
          content="Nunca te has preguntado, ¿qué nos lleva a tomar la decisión de dejar nuestro puesto de trabajo en el sector Tech?"
        />
        <meta
          name="twitter:image"
          content="https://leonardopoza.com/post-images/featured-motivos-dejar-trabajo.png"
        />
        <meta
          property="og:url"
          content="https://leonardopoza.com/blog/motivos-por-los-que-dejar-tu-trabajo"
        />
        <meta
          name="twitter:card"
          content="Nunca te has preguntado, ¿qué nos lleva a tomar la decisión de dejar nuestro puesto de trabajo en el sector Tech?"
        />
        <meta name="twitter:creator" content="@leonardopoza" />
      </MetaTags>

      <WithSubnavigation />

      <Heading
        className="post-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "10vh" }}
        marginBottom={{ base: "2vh", md: "5vh" }}
        fontSize={{ base: "9vh", md: "12vh" }}
        lineHeight={{ base: "90%", md: "100%" }}
      >
        {post.title}
      </Heading>

      <HStack
        spacing="24px"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Box>
          <Heading className="blog-date" fontSize={{ base: "2vh", md: "4vh" }}>
            {post.publication_date}
          </Heading>
        </Box>
        <Box>
          <Badge
            textTransform={"capitalize"}
            background={"#fcfcbf"}
            color={"#000000"}
            fontSize={"sm"}
            alignSelf={"center"}
            width={"auto"}
            pl={4}
            pr={4}
            pt={1}
            pb={1}
            rounded={"md"}
          >
            {post.tag}
          </Badge>
        </Box>
      </HStack>

      <Container backgroundColor={"#ffffff"} minW="100%">
        <Stack
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
          as={Box}
          textAlign={"left"}
          spacing={{ base: 5, md: 10 }}
          py={{ base: 20, md: 20 }}
        >
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            El sector tecnológico es claramente privilegiado. Sin duda alguna
            existe más demanda de perfiles para este sector que los
            profesionales cualificados para ocupar estas posiciones. Esto lleva
            a las empresas a hacerse la siguiente pregunta:{" "}
            <b>¿qué buscan los/as profesionales IT en un nuevo empleo?</b>
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Esta situación está haciendo que las empresas estén poniendo mucho
            esfuerzo y dedicación para mejorar sus condiciones laborales y para
            que sus ofertas de empleo sean cada vez más atractivas. Pero esto no
            solo es una cuestión de atracción, sino también de retención. La
            rotación media en el sector tecnológico es del <b>13,2%</b> según
            podemos leer en este estudio realizado por LinkedIn. Seguramente os
            resultará un número llamativo, pero lo es más el coste que supone
            para las empresas que un empleado decida abandonarla.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Tomar la decisión de cambiar de empleo no es una decisión sencilla.
            Es más, tiene que ser una decisión muy meditada, compartida en
            muchos casos con la familia y que no deja de ser un riesgo. Por
            esto, no solemos encontrar candidatos que se cambien por un único
            motivo. Hace no mucho, lancé una encuesta para recopilar información
            sobre cómo está afectando el COVID-19 al sector tecnológico y una de
            las preguntas era precisamente esta: ¿cuáles son tus principales
            motivos para valorar un cambio de empleo? Pronto compartiré con
            todos vosotros las conclusiones sacadas de este estudio.
          </Text>

          <center>
            <img
              src="/post-images/featured-motivos-dejar-trabajo.png"
              alt="9 motivos por los que dejar tu trabajo en el sector Tech"
            />
          </center>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Ahora bien, ¿cuáles son los principales motivos por los cuales los
            profesionales IT buscan un cambio de empleo?
          </Text>

          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
            Remoto
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            <i>Posibilidad de trabajar de forma remota</i>
          </Text>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            La posibilidad de trabajar de forma remota ya era uno de los motivos
            principales para el cambio de empleo, pero ahora, con la etapa
            histórica que nos esta tocando vivir podríamos decir que es el
            principal. El paradigma del trabajo ha cambiado, eso es una realidad
            y muchas empresas ya se están adaptando a ello. Para los ingenieros,
            al igual que para otros sectores, esto es un <b>MUST</b> en su próxima
            búsqueda de empleo. Es más, a los que no se os ofrezca esta
            posibilidad, poco tardaréis en buscar alternativas.
          </Text>

          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
          Salario competitivo
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            <i>Salario justo con el estado del mercado</i>
          </Text>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
          Según la pirámide de Maslow, el salario estaría en la base de la misma como representación de una necesidad fisiológica. Obviamente el salario es uno de los principales motivos para el cambio, pero una cosa a reseñar es que este pierde peso cuanto más altos son los salarios. Los ingenieros y esto está comprobado con muchos candidatos, preferimos rodearnos de compañeros con gran talento de los cuales se puede aprender a un par de miles de euros más en el salario. Por otro lado, a todos los que están empezando en este sector, siempre les digo lo mismo: no busquéis un super salario, buscad una empresa que os acompañe y os forme, eso vale mucho más de dos mil euros.
          </Text>

          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
          Talento de los empleados
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            <i>¿Cuánto aprenderé de mis compañeros?</i>
          </Text>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
          Esto desde mi punto de vista y el de muchos candidatos es algo fundamental. Trabajar con gente competente, con talento, que te haga evolucionar y crecer profesionalmente. Esos compañeros que, tomando un café, té iluminan y aportan una solución al problema con el que estas lidiando. Esto vale millones. En mi último post, donde daba consejos para afrontar una entrevista para el sector IT, comentaba que antes de decidirse por un cambio, se investigue quien trabaja en esa empresa. Es una forma de hacerte una idea del talento que tienen. Otra forma de asegurarte es prestar atención durante el proceso de selección, fíjate en quien te entrevista, que nivel técnico tiene…
          </Text>

          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
          Proyectos interesantes y retadores
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            <i>Proyectos que no nos supongan un reto profesional</i>
          </Text>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
          Sentir que cada día es un reto, que trabajas para un proyecto interesante y complejo, es una de las mayores aspiraciones para un profesional IT. Seguramente que en todos los sectores ocurre lo mismo, trabajar en algo interesante te lleva a hacerlo con más ganas e ilusión. Por lo contrario, si estás trabajando en un proyecto que no te llena, que hace que no te hace sentir realizado, poco a poco te llevara a la búsqueda de alternativas.
          </Text>

          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
          Flexibilidad horaria y conciliación
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            <i>La necesidad de conciliación es algo muy demandado</i>
          </Text>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
          No sólo los que somos papás o mamás necesitamos la flexibilidad horaria. Cuando escuchas hablar por ahí de conciliación, parece que solo la necesitemos los que tenemos hijos. Ni mucho menos. Todo el mundo tiene una vida después de lo profesional y todas ellas son respetables y necesitan de tiempo para cuidarlas. Si tu jornada comienza a las 8:00AM y termina a las 08:00PM, te aseguro que dentro de poco se convertirá en un motivo para buscar un cambio.
          </Text>

          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
            Cultura y ambiente laboral
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            <i>Cultura y ambiente laboral</i>
          </Text>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
          ¿Trabajarías en una empresa con la cual no compartas los valores? Es una pregunta sencilla y estoy seguro de que la respuesta es no. Esto es algo que sucede más de lo que esperaba, entrevisto a muchos candidatos que me dice: “la empresa se ha transformado en algo que no me hace sentir cómodo” y eso, claramente impulsa a la gente a buscar empleo. Igualmente, importante es el ambiente laboral, estar a gusto con tus compañeros es algo fundamental, no nos engañemos, compartimos con ellos muchas horas al cabo del año, 1.826 horas.
          </Text>

          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
          Formación y crecimiento
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            <i>Vivimos en un sector de constante cambio y el crecimiento profesional es muy importante</i>
          </Text>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
          Estamos todos de acuerdo cuando decimos que la formación continua en nuestro sector es fundamental. Da igual que lleves un año como profesional o diez, la formación tiene que ser continua. Cuando una persona se siente estancada y la empresa no le ayuda en su carrera profesional, esta se siente frustrada. Una empresa, crea buenos productos si tiene buenos profesionales y para que esto tenga continuidad en el tiempo, los profesionales tenemos que estar bien formados.
          </Text>

          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
          Beneficios añadidos
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            <i>Seguro médico, transporte, clases de inglés…</i>
          </Text>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
          Viendo los resultados de la encuesta de la que os hablaba, tan solo un 4,24% de los encuestados priorizo los beneficios añadidos a la hora de la búsqueda de empleo. Sorprendente, sobre todo cuando esto supone un montante económico bastante alto al final del año.
          </Text>
          
          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
          Calidad del liderazgo
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            <i>Un buen manager es crucial</i>
          </Text>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Tener un líder/responsable/manager que no sea de calidad es una losa para muchos. Como decíamos antes, a todos nos gusta rodearnos de gente con talento, pero si nuestro líder no lo tiene es un verdadero problema. Ni que decir tiene que, si es autoritario, poco empático y rozas con el/ella en algún aspecto, será el empujón definitivo al abismo.
          </Text>

          <hr class="solid" />

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
          Llegado a este punto, lo que está claro es que las mesas de ping-pong, la fruta gratis, el futbolín y las XBOX molan mucho, fomentan el buen ambiente, pero no están dentro de los principales motivos de cambio. Para ser sinceros, si un candidato quisiera trabajar en mi empresa sólo por tener futbolín, me plantearía seriamente si contratarlo.
          </Text>

        </Stack>

        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={4}
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
        >
          <Box>
            <a href={post.substrack_link} target={"new"}>
              <Button
                width={"100%"}
                height={"50px"}
                backgroundColor={"#000000"}
                color={"#ffffff"}
                rounded={0}
                rightIcon={<SiSubstack />}
                fontWeight={600}
                textTransform="uppercase"
                _hover={{ backgroundColor: "#f10965" }}
              >
                suscribete
              </Button>
            </a>
          </Box>
          <Box>
            <a href={post.share_twitter} target={"new"}>
              <Button
                width={"100%"}
                height={"50px"}
                backgroundColor={"#000000"}
                color={"#ffffff"}
                rounded={0}
                rightIcon={<FaTwitter />}
                fontWeight={600}
                textTransform="uppercase"
                _hover={{ backgroundColor: "#f10965" }}
              >
                Share
              </Button>
            </a>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 8, md: 10 }}
          marginTop={{ base: "100px", md: "100px" }}
          marginBottom={{ base: "220px", md: "220px" }}
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
        >
          <Stack spacing={4}>
            <Heading
              className="latest-post-heading"
              marginBottom={{ base: "15vw", md: "5vw" }}
              marginTop={{ base: "0vw", md: "3vw" }}
              fontSize={{ base: "10vh", md: "12vh" }}
              lineHeight={{ base: "80%", md: "100%" }}
            >
              Posts<br></br>similares<span style={{ color: "#f10965" }}>.</span>
            </Heading>
          </Stack>
          <VStack spacing={4} align="stretch">
            <a href={post.related_post_left.href} className="card-zoomer">
              <Card
                borderRadius="0"
                boxShadow={"xl"}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Stack>
                  <CardBody>
                    <Heading size="md">{post.related_post_left.title}</Heading>
                    <Text py="2">{post.related_post_left.spoiler}</Text>
                  </CardBody>
                </Stack>
              </Card>
            </a>
            <a href={post.related_post_right.href} className="card-zoomer">
              <Card
                borderRadius="0"
                boxShadow={"xl"}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Stack>
                  <CardBody>
                    <Heading size="md">{post.related_post_right.title}</Heading>

                    <Text py="2">{post.related_post_right.spoiler}</Text>
                  </CardBody>
                </Stack>
              </Card>
            </a>
          </VStack>
        </SimpleGrid>
      </Container>

      <Footer />
    </Fragment>
  );
}

