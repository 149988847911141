import { Fragment } from "react";
import {
  Heading,
  Text,
  Stack,
  SimpleGrid,
  Card,
  CardBody,
} from "@chakra-ui/react";

import { Footer } from "../components/footer";
import { WithSubnavigation } from "../components/navbar";
import { POSTS } from "../configuration/blog-post";
import { MetaTags } from "react-meta-tags";

export function Blog() {
  return (
    <Fragment>
      <MetaTags>
        <title>Leonardo Poza - Blog</title>
        <meta name="description" content="Escribo sobre temas relacionados con la selección de perfiles técnicos. Recruitment, confección de equipos, escalado, formación, atracción y fidelización de talento..." />
        <meta property="og:title" content="Leonardo Poza - Blog" />
        <meta property="og:description" content="Escribo sobre temas relacionados con la selección de perfiles técnicos. Recruitment, confección de equipos, escalado, formación, atracción y fidelización de talento..." />
        <meta property="og:image" content="/favicon.png" />
        <meta property="twitter:title" content="Leonardo Poza - Blog" />
        <meta property="twitter:description" content="Escribo sobre temas relacionados con la selección de perfiles técnicos. Recruitment, confección de equipos, escalado, formación, atracción y fidelización de talento..." />
        <meta property="twitter:image" content="/favicon.png" />
      </MetaTags>
      <WithSubnavigation />
      <Heading
        className="hero-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "10vh" }}
        marginBottom={{ base: "2vh", md: "5vh" }}
        fontSize={{ base: "9vh", md: "12vh" }}
      >
        Blog<span style={{ color: "#f10965" }}>.</span>
      </Heading>

      <Text
        color={"1e283b"}
        fontSize={"lg"}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        Escribo sobre temas relacionados con la selección de perfiles técnicos.
        Recruitment, confección de equipos, escalado, formación, atracción y
        fidelización de talento...
      </Text>

      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={{ base: 8, md: 10 }}
        marginTop={{ base: "5vh", md: "10vh" }}
        marginBottom={{ base: "220px", md: "220px" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        {POSTS.map((post) => (
          <Stack spacing={3}>
            <a href={post.href} className="card-zoomer">
              <Card
                borderRadius="0"
                boxShadow={"xl"}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Stack>
                  <CardBody>
                    <Heading size="md">{post.title}</Heading>
                    <Text py="2">{post.spoiler}</Text>
                  </CardBody>
                </Stack>
              </Card>
            </a>
          </Stack>
        ))}
      </SimpleGrid>

      <Footer />
    </Fragment>
  );
}
