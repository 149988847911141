import { Fragment } from "react";
import {
  Box,
  Heading,
  VStack,
  Text,
  Stack,
  Badge,
  Button,
  SimpleGrid,
  Card,
  CardBody,
  HStack,
  Container,
} from "@chakra-ui/react";

import { Footer } from "../components/footer";
import { WithSubnavigation } from "../components/navbar";
import { FaTwitter } from "react-icons/fa";
import { SiSubstack } from "react-icons/si";
import { MetaTags } from "react-meta-tags";

var post = {
  title: "Luchar por el equipo.",
  publication_date: "04 de Enero de 2021",
  tag: "equipo",
  substrack_link: "https://leonardopoza.substack.com",
  share_twitter:
    "https://twitter.com/intent/tweet?text=Luchar%20por%20el%20equipo&url=https://leonardopoza.com/blog/luchar-por-el-equipo&via=leonardopoza&hashtags=equipo",
  related_post_left: {
    title: "Ciao Payvision Bella",
    spoiler:
      "Dejo Payvision. Después de casi 13 años, un 35% de mi vida, dejo Payvision. Una decisión que me ha costado mucho tomar, pero cada día que pasa me doy cuenta de que es la más...",
    href: "/blog/ciao-payvision-bella",
  },
  related_post_right: {
    title: "Buenas prácticas en procesos de selección",
    spoiler:
      "Como bien sabéis, llevo muchos años trabajando en la industria del desarrollo de software, toda mi carrera profesional, ni mas ni menos...",
    href: "/blog/buenas-practicas-procesos-seleccion",
  },
};

export function LuchaPorElEquipo() {
  return (
    <Fragment>
      <MetaTags>
        <title>Luchar por el equipo.</title>
        <meta name="description" content="El tenis es un deporte solitario. Puedes pensar que no lo es tanto, pero el mismo Andre Agassi, número uno de la ATP durante 101 semanas, lo describe así en sus memorias “Open”..." />
        <meta property="og:title" content="Luchar por el equipo." />
        <meta property="og:description" content="El tenis es un deporte solitario. Puedes pensar que no lo es tanto, pero el mismo Andre Agassi, número uno de la ATP durante 101 semanas, lo describe así en sus memorias “Open”..." />
        <meta property="og:image" content="/post-images/featured-luchar-por-el-equipo.png" />
        <meta property="twitter:title" content="Luchar por el equipo." />
        <meta property="twitter:description" content="El tenis es un deporte solitario. Puedes pensar que no lo es tanto, pero el mismo Andre Agassi, número uno de la ATP durante 101 semanas, lo describe así en sus memorias “Open”..." />
        <meta property="twitter:image" content="/post-images/featured-luchar-por-el-equipo.png" />
      </MetaTags>

      <WithSubnavigation />

      <Heading
        className="post-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "10vh" }}
        marginBottom={{ base: "2vh", md: "5vh" }}
        fontSize={{ base: "9vh", md: "12vh" }}
        lineHeight={{ base: "90%", md: "100%" }}
      >
        {post.title}
      </Heading>

      <HStack
        spacing="24px"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Box>
          <Heading className="blog-date" fontSize={{ base: "2vh", md: "4vh" }}>
            {post.publication_date}
          </Heading>
        </Box>
        <Box>
          <Badge
            textTransform={"capitalize"}
            background={"#fcfcbf"}
            color={"#000000"}
            fontSize={"sm"}
            alignSelf={"center"}
            width={"auto"}
            pl={4}
            pr={4}
            pt={1}
            pb={1}
            rounded={"md"}
          >
            {post.tag}
          </Badge>
        </Box>
      </HStack>

      <Container backgroundColor={"#ffffff"} minW="100%">
        <Stack
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
          as={Box}
          textAlign={"left"}
          spacing={{ base: 5, md: 10 }}
          py={{ base: 20, md: 20 }}
        >
          <Text fontSize={"lg"} align={"justify"}>
            El tenis es un deporte solitario. Puedes pensar que no lo es tanto,
            pero el mismo Andre Agassi, número uno de la ATP durante 101
            semanas, lo describe así en sus memorias{" "}
            <a href="https://www.amazon.es/Open-Memorias-Nefelibata-Andre-Agassi/dp/8415945485">
              “Open”
            </a>
            . Llega a ponerlo en comparación con el boxeo. Cito textualmente un
            fragmento de este libro: “Solo los boxeadores pueden entender la
            soledad de los tenistas, y aun así ellos tienen a sus asistentes
            sentados en las esquinas, además de los managers. Incluso el
            oponente del boxeador le proporciona una especie de compañía; es
            alguien a quien puede encararse y al que puede gruñir. Pero en el
            tenis te plantas frente a tu enemigo, intercambias golpes con él,
            pero nunca lo tocas ni hablas con él, ni haces nada con él. Las
            reglas lo prohíben incluso que el tenista hable con su entrenador
            cuando se encuentra en la pista. A veces se compara la soledad del
            tenista con la del corredor de fondo, pero yo no puedo evitar
            reírme. Al menos ese corredor puede oler y sentir a sus
            contrincantes, Se encuentran a escasos centímetros de distancia. En
            el tenis, estas en una isla. De todos los deportes que practican
            hombres y mujeres, el tenis es el más parecido a una reclusión en
            régimen de aislamiento que, inevitablemente, propicia la
            conversación con uno mismo.”
          </Text>
          <Text fontSize={"lg"} align={"justify"}>
            Agassi es el único jugador de la historia que ha ganado los siete
            títulos más prestigiosos en el tenis individual masculino y lo hizo
            odiando el tenis. Es increíble como el ser humano, puede aguantar
            tanto tiempo haciendo algo al máximo, cuando realmente lo que
            quieres hacer es otra cosa.
          </Text>
          <Text fontSize={"lg"} align={"justify"}>
            Pero todo este preámbulo no es para hablaros del poder de la mente,
            ni de si es sano hacer algo que no te gusta durante tanto tiempo a
            pesar de llegar a ser el mejor del mundo. Quiero hablaros de
            completamente lo opuesto a la soledad que sentía Agassi jugando al
            tenis. El equipo. De ese sentimiento de entrega, de lucha individual
            por el bien colectivo. Para mí el equipo es fundamental. Trabajar
            bien rodeado, sin duda, te hace mejor, te reta, tus compañeros te
            acompañan, se comparten los éxitos y te ayudan a levantarte cuando
            te tropiezas. Conseguir crear un verdadero equipo no es sencillo y
            si lo descuidas se rompe. Desde mi punto de vista, compartir valores
            y principios es algo fundamental. Pero valores de verdad, no solo
            los que se escriben en la web o en las paredes de las oficinas.
          </Text>
          <Text fontSize={"lg"} align={"justify"}>
            Esto es algo que todo el mundo sabe, pero encontrar un equipo de
            trabajo donde realmente exista un equipo, es complicado. Y yo puedo
            decir que siempre he tenido la suerte de pertenecer a buenos
            equipos. Y ahora, con mi último cambio, vuelvo a ser afortunado y
            tengo la oportunidad de trabajar con un equipazo.
          </Text>
          <Text fontSize={"lg"} align={"justify"}>
            Os dejo una pregunta. ¿Sabemos mirar el marcador del equipo o nos
            empeñamos en maximizar los tantos que cada uno de nosotros hacemos?
          </Text>
        </Stack>

        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={4}
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
        >
          <Box>
            <a href={post.substrack_link} target={"new"}>
              <Button
                width={"100%"}
                height={"50px"}
                backgroundColor={"#000000"}
                color={"#ffffff"}
                rounded={0}
                rightIcon={<SiSubstack />}
                fontWeight={600}
                textTransform="uppercase"
                _hover={{ backgroundColor: "#f10965" }}
              >
                suscribete
              </Button>
            </a>
          </Box>
          <Box>
            <a href={post.share_twitter} target={"new"}>
              <Button
                width={"100%"}
                height={"50px"}
                backgroundColor={"#000000"}
                color={"#ffffff"}
                rounded={0}
                rightIcon={<FaTwitter />}
                fontWeight={600}
                textTransform="uppercase"
                _hover={{ backgroundColor: "#f10965" }}
              >
                Share
              </Button>
            </a>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 8, md: 10 }}
          marginTop={{ base: "100px", md: "100px" }}
          marginBottom={{ base: "220px", md: "220px" }}
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
        >
          <Stack spacing={4}>
            <Heading
              className="latest-post-heading"
              marginBottom={{ base: "15vw", md: "5vw" }}
              marginTop={{ base: "0vw", md: "3vw" }}
              fontSize={{ base: "10vh", md: "12vh" }}
              lineHeight={{ base: "80%", md: "100%" }}
            >
              Posts<br></br>similares<span style={{ color: "#f10965" }}>.</span>
            </Heading>
          </Stack>
          <VStack spacing={4} align="stretch">
            <a href={post.related_post_left.href} className="card-zoomer">
              <Card
                borderRadius="0"
                boxShadow={"xl"}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Stack>
                  <CardBody>
                    <Heading size="md">{post.related_post_left.title}</Heading>
                    <Text py="2">{post.related_post_left.spoiler}</Text>
                  </CardBody>
                </Stack>
              </Card>
            </a>
            <a href={post.related_post_right.href} className="card-zoomer">
              <Card
                borderRadius="0"
                boxShadow={"xl"}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Stack>
                  <CardBody>
                    <Heading size="md">{post.related_post_right.title}</Heading>

                    <Text py="2">{post.related_post_right.spoiler}</Text>
                  </CardBody>
                </Stack>
              </Card>
            </a>
          </VStack>
        </SimpleGrid>
      </Container>

      <Footer />
    </Fragment>
  );
}
