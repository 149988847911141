import { Fragment } from "react";
import { Heading } from "@chakra-ui/react";
import { Footer } from "../components/footer";
import { WithSubnavigation } from "../components/navbar";
import { MetaTags } from "react-meta-tags";
import { TheTeacher } from "../components/academy/academy-teacher";
import { AcademyValue } from "../components/academy/academy-value";
import { AcademyFormats } from "../components/academy/academy-formats";
import { AcademyContent } from "../components/academy/academy-content";
import { NextEvent } from "../components/academy/academy-next-event";

export function Taller() {
  return (
    <Fragment>
      <MetaTags>
        <title>Leonardo Poza - Taller</title>
        <meta name="description" content="Escribo sobre temas relacionados con la selección de perfiles técnicos. Recruitment, confección de equipos, escalado, formación, atracción y fidelización de talento..." />
        <meta property="og:title" content="Leonardo Poza - Taller" />
        <meta property="og:description" content="Escribo sobre temas relacionados con la selección de perfiles técnicos. Recruitment, confección de equipos, escalado, formación, atracción y fidelización de talento..." />
        <meta property="og:image" content="/favicon.png" />
        <meta property="twitter:title" content="Leonardo Poza - Taller" />
        <meta property="twitter:description" content="Escribo sobre temas relacionados con la selección de perfiles técnicos. Recruitment, confección de equipos, escalado, formación, atracción y fidelización de talento..." />
        <meta property="twitter:image" content="/favicon.png" />
      </MetaTags>
      <WithSubnavigation />
      <Heading
        className="hero-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "10vh" }}
        marginBottom={{ base: "2vh", md: "5vh" }}
        fontSize={{ base: "9vh", md: "12vh" }}
      >
        Taller de Recruiting IT<span style={{ color: "#66C7F4" }}>.</span>
      </Heading>

      <Heading
        className="hero-skills"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "20vh", md: "27vh" }}
      >
        #recruitment. &nbsp;&nbsp; #formación.&nbsp;&nbsp; #atracción.&nbsp;&nbsp;
        #contratación.
      </Heading>

      <NextEvent />
      <TheTeacher />
      <AcademyValue />
      <AcademyFormats />
      <AcademyContent />
      <NextEvent />
      <Footer />
    </Fragment>
  );
}
