import { Heading, Image } from "@chakra-ui/react";
import ReactGA from "react-ga";
import { useEffect } from "react";

export function Header() {
  useEffect(() => {
    ReactGA.initialize("UA-70983243-4");
  }, []);
  return (
    <>
      <Image
        borderRadius="full"
        boxSize="150px"
        src="/leonardo.poza.jpeg"
        alt="Leonardo Poza"
        position={"relative"}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "10vw", md: "5vw" }}
        marginBottom={{ base: "0vw", md: "2vw" }}
      />

      <Heading
        className="hero-heading"
        marginLeft={{ base: "5%", md: "19.7%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "10vh" }}
        fontSize={{ base: "9vh", md: "16vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        Leonardo <br></br>Poza<span style={{ color: "#C9E500" }}>.</span>
      </Heading>

      <Heading
        className="hero-skills"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        #recruitment. &nbsp;&nbsp; #team.&nbsp;&nbsp; #development.&nbsp;&nbsp;
        #consultancy.
      </Heading>
    </>
  );
}
