import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";

export function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box
      className="navbar"
      bg={useColorModeValue("#ffffff", "#ffffff")}
      marginLeft={{ base: "0%", md: "20.3%" }}
    >
      <Flex>
        <Flex
          flex={{ base: 1, md: "auto" }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            marginLeft={{ base: "10%", md: "20.3%" }}
            marginBottom={{ base: "5%", md: "20.3%" }}
            icon={
              isOpen ? <CloseIcon w={5} h={5} /> : <HamburgerIcon w={8} h={8} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Flex display={{ base: "none", md: "flex" }}>
            <DesktopNav />
          </Flex>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  return (
    <Stack direction={"row"} spacing={4} marginLeft="-40px">
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
              marginRight="20px"
                href={navItem.href ?? "#"}
                fontWeight="bold"
                color={"#B0B5B3 !important"}
                _hover={{
                  textDecoration: "none",
                  color: "#f10965 !important",
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("#1e283b", "#1e283b")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle} >
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        align={"center"}
        color={"#ffffff !important;"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={900}
          marginLeft="25px"
          color={useColorModeValue("white.900", "white.900")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.100", "gray.700")}
          align={"center"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    /*
      label: 'Inspiration',
      children: [
        
        {
          label: 'Explore Design Work',
          subLabel: 'Trending Design to inspire you',
          href: '#',
        },
        {
          label: 'New & Noteworthy',
          subLabel: 'Up-and-coming Designers',
          href: '#',
        },
       
      ],
       */
  },

  {
    label: "home",
    href: "/",
  },
  /*
  {
    label: "Sobre mi",
    href: "/sobre-mi",
  },
  {
    label: "blog",
    href: "/blog",
  },
  */
  {
    label: "newsletter",
    href: "https://leonardopoza.substack.com/",
  },
];
