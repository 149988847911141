import {
  Heading,
  Box,
  Text,
  Stack,
  Flex,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";

export function MyStory() {
  return (
    <Box className="my-history-box" marginTop={{ base: "0vw", md: "2vw" }}>
      &nbsp;
      <Heading
        className="history-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "15vw", md: "5vw" }}
        fontSize={{ base: "10vh", md: "12vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        Mi historia<span style={{ color: "#f10965" }}>:</span>
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "220px", md: "220px" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Stack spacing={4}>
          <Text className="bio-history" fontWeight={"bold"}>
            Burgalés. Feliz al cuadrado. Padre por duplicado. Afortunado.
          </Text>
          <Text>
            He pasado más de 15 años desarrollando software y liderando equipos
            de desarrollo. También he vivido la experiencia de ser el
            responsable de selección de los perfiles tecnológicos de una fintech
            durante 2 años. Si ponemos todo eso en una coctelera, podéis
            imaginar las mil y una experiencias vividas.
            <br></br>
            <br></br>
            En Manfred tratamos de cambiar el Recruitment tecnológico. De
            hacerlo de una forma diferente, de hacerlo poniendo al candidato en
            el centro del proceso. Y todas y cada una de mis vivencias como
            ingeniero, me están sirviendo para ayudar en nuestro objetivo. Somos
            diferentes, somos especiales y creemos en lo que hacemos.
          </Text>
        </Stack>
        <Flex>
          <Image
            boxShadow={"xl"}
            rounded={"md"}
            alt={"feature image"}
            height={"400"}
            width="100%"
            src={"/yo.avif"}
            objectFit={"cover"}
            marginTop={"-3"}
          />
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
