import { Fragment } from "react";

import {
  Box,
  Heading,
  Container,
  Text,
  Stack,
  Badge,
  Button,
  SimpleGrid,
  Card,
  CardBody,
  HStack,
  VStack,
} from "@chakra-ui/react";

import { Footer } from "../components/footer";
import { WithSubnavigation } from "../components/navbar";
import { FaTwitter } from "react-icons/fa";
import { SiSubstack } from "react-icons/si";
import { MetaTags } from "react-meta-tags";

var post = {
  title: "Tips and Tricks for your first IT interview!",
  publication_date: "13 de Agosto de 2020",
  tag: "Recruiting",
  substrack_link:
    "https://leonardopoza.substack.com/?utm_source=leonardopoza.com&utm_medium=web&utm_campaign=tips-and-tricks-for-interview",
  share_twitter:
    "https://twitter.com/intent/tweet?text=Tips%20and%20tricks%20para%20tu%20primera%20entrevista%20tech&url=https://leonardopoza.com/blog/tips-and-tricks-first-interview&via=leonardopoza&hashtags=Recruiting",
  related_post_left: {
    title: "Luchar por el equipo",
    spoiler:
      "El tenis es un deporte solitario. Puedes pensar que no lo es tanto, pero el mismo Andre Agassi, número uno de la ATP durante 101 semanas, lo describe así en sus memorias “Open”...",
    href: "/blog/luchar-por-el-equipo",
  },
  related_post_right: {
    title: "Buenas prácticas en procesos de selección",
    spoiler:
      "Como bien sabéis, llevo muchos años trabajando en la industria del desarrollo de software, toda mi carrera profesional, ni mas ni menos...",
    href: "/blog/buenas-practicas-procesos-seleccion",
  },
};

export function TipsAndTricksForInterview() {
  return (
    <Fragment>
      <MetaTags>
        <title>Tips and Tricks for your first IT interview!</title>
        <meta
          name="description"
          content="Una serie de 'trips and tricks' para poder ir mejor preparado a tu entrevista de trabajo. ¿Qué debo saber antes de aplicar a una posición? ¿Dónde puedo encontrar información sobre la empresa?"
        />
        <meta
          property="og:title"
          content="Tips and Tricks for your first IT interview!"
        />
        <meta
          property="og:description"
          content="Una serie de 'trips and tricks' para poder ir mejor preparado a tu entrevista de trabajo. ¿Qué debo saber antes de aplicar a una posición? ¿Dónde puedo encontrar información sobre la empresa?"
        />
        <meta
          property="og:image"
          content="https://leonardopoza.com/post-images/featured-procesos-seleccion.png"
        />
        <meta
          name="twitter:title"
          content="Tips and Tricks for your first IT interview!"
        />
        <meta
          name="twitter:description"
          content="Una serie de 'trips and tricks' para poder ir mejor preparado a tu entrevista de trabajo. ¿Qué debo saber antes de aplicar a una posición? ¿Dónde puedo encontrar información sobre la empresa?"
        />
        <meta
          name="twitter:image"
          content="https://leonardopoza.com/post-images/featured-procesos-seleccion.png"
        />
        <meta
          property="og:url"
          content="https://leonardopoza.com/blog/buenas-practicas-procesos-seleccion"
        />
        <meta
          name="twitter:card"
          content="Una serie de 'trips and tricks' para poder ir mejor preparado a tu entrevista de trabajo. ¿Qué debo saber antes de aplicar a una posición? ¿Dónde puedo encontrar información sobre la empresa?"
        />
        <meta name="twitter:creator" content="@leonardopoza" />
      </MetaTags>
      <WithSubnavigation />
      <Heading
        className="post-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "10vh" }}
        marginBottom={{ base: "2vh", md: "5vh" }}
        fontSize={{ base: "9vh", md: "12vh" }}
        lineHeight={{ base: "90%", md: "100%" }}
      >
        {post.title}
      </Heading>

      <HStack
        spacing="24px"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Box>
          <Heading className="blog-date" fontSize={{ base: "2vh", md: "4vh" }}>
            {post.publication_date}
          </Heading>
        </Box>
        <Box>
          <Badge
            textTransform={"capitalize"}
            background={"#fcfcbf"}
            color={"#000000"}
            fontSize={"sm"}
            alignSelf={"center"}
            width={"auto"}
            pl={4}
            pr={4}
            pt={1}
            pb={1}
            rounded={"md"}
          >
            {post.tag}
          </Badge>
        </Box>
      </HStack>

      <Container backgroundColor={"#ffffff"} minW="100%">
        <Stack
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
          as={Box}
          textAlign={"left"}
          spacing={{ base: 5, md: 10 }}
          py={{ base: 20, md: 20 }}
        >
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Entre las situaciones cotidianas que nos hacen segregar adrenalina,
            se encuentran momentos de acción, deportes de riesgo y situaciones
            límite. Esta hormona te hace estar alerta o mantenernos despiertos
            en momentos de exigencia. Pues bien, seguro que cuando afrontamos
            una entrevista de trabajo, aparece nuestra amiga la adrenalina.
            Depende de la persona, pero esta situación no suele ayudar en este
            tipo de situaciones. Si te mantiene despierto o te hace estar más
            ágil, te ayudará, pero si no es así, te puede jugar una mala pasada.
            En muchas ocasiones como entrevistador, me he encontrado con
            personas a las que les hace estar atacado de los nervios y eso no
            ayuda. En estos casos, siempre trato de hacer que el candidato se
            relaje y tenga su espacio para demostrar lo que realmente puede
            aportar a la empresa.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Si a todo esto le sumas que puedes estar afrontando tu primera
            entrevista para un sector desconocido para ti, las cosas se
            complican. Se tiene que afrontar la búsqueda de empleo desde la
            tranquilidad, pensando bien qué es lo que buscas y lo que es más
            importante, teniendo claro que es lo que no quieres.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Desde hace un par de años he tenido el placer de dar varias charlas
            a alumnos de bootcamps y todos ellos tienen respeto a la hora de
            afrontar su primera entrevista. Lógico, han tomado la decisión de
            dejar todo, dar un golpe de timón a su vida y hacer una inversión a
            la cual ahora tienen que empezar a sacar partido. En la misma
            situación están aquellos que recién salgan de la universidad,
            terminen sus estudios o incluso para aquellos profesionales que
            busquen un cambio de empleo.
          </Text>
          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Pues bien, hace no mucho me contactaron de Ironhack para ver si
            quería colaborar con ellos, dando una charla a los alumnos de sus
            últimos bootcamps. Y el que me conozca, sabe que me apunto a un
            bombardeo y les dije que sí. Para esta ocasión, pensé que les
            vendría bien una serie de consejos para afrontar su primera
            entrevista para el mundo IT. Algo, que ya pude hacer presencialmente
            con los chicos de Codenotch. Obviamente, no es la verdad absoluta,
            solo son mis consejos, míos, basados en mi experiencia. Solo espero
            que les sirvieran para encontrar el empleo que mejor les encaje.
            Aquí os dejo los 8 consejos que les di:
          </Text>

          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
            1 - Think before applying!
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            What are you really looking for?<br></br>
            <br></br>
            Es el momento de pensar cual es tu trabajo ideal, obviamente siendo
            realistas. ¿Te quieres especializar en ciertas tecnologías? ¿Te
            interesan empresas dedicadas a cierto sector? Es el momento de
            identificar todas estas cosas que te atraen para una búsqueda de
            empleo. Pero, igualmente importante es identificar que cosas no
            quieres. Tenerlo claro, te hará estar alerta y descartar ofertas o
            empresas que te den sospechas de tener aquello que no quieres para
            tu futuro empleo.
          </Text>
          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
            2 - Read carefully the vacancies!
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            They’ll give you a lot of information!<br></br>
            <br></br>
            Existen muchísimas formas de redactar una oferta de empleo, pero no
            todas son transparentes. Me arriesgaría a decir que hay ofertas de
            empleo que no tienen claro qué tipo de candidato están buscando ni
            cuales son las necesidades que tienen. Lee cuidadosamente, si lo
            haces entre líneas, puedes percibir que tipo de cultura tiene la
            empresa, como trabajan y como de cuidadosos son con el trabajo que
            entregan.
          </Text>
          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
            3 - Be yourself!
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            And be honest about your knowledge!<br></br>
            <br></br>
            Se tú mismo. Así de claro, sin trampa ni cartón. Si te contratan,
            que sea por lo que eres, por lo que puedes aportar o por el
            potencial que tienes. Se sincero con la empresa, pero lo mas
            importante contigo mismo. Si no sabes algo, no pasa nada, dilo
            claramente.
          </Text>
          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
            4 - Ask, ask, ask…
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Don’t be shy! Seek answers to all your questions.<br></br>
            <br></br>
            Si, preguntad. Una entrevista de trabajo a de ser bidireccional. La
            empresa tiene que garantizar que tu eres el candidato idóneo para
            cubrir la posición, pero tú también debes tener claro que esa es la
            empresa donde quieres encaminar un nuevo futuro profesional. Por lo
            tanto, asegúrate de ello y la mejor forma que tienes para hacerlo es
            preguntando. No te quedes con ninguna duda. ¿Cómo vais a medir si
            estoy rindiendo correctamente? ¿De que estáis orgullosos como
            empresa? Hay muchas preguntas que se pueden hacer y si no os
            responden o se incomodan, tal vez no es vuestro sitio.
          </Text>
          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
            5 - Request feedback!
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Getting feedback should be a must for you!<br></br>
            <br></br>
            En muchos de los procesos de selección a los que os enfrentéis, os
            pedirán hacer una prueba técnica (tema que da para otro post),
            tendréis una entrevista técnica o ambas cosas. Siempre y digo
            siempre pedid feedback, motivos por los que os han descartado,
            motivos por los cuales la prueba entregada no encaja con lo que
            están buscando. De todos los procesos de selección se aprende, será
            una lección que os llevaréis en vuestra mochila para futuros
            procesos, pero otro motivo fundamental es el respeto. Siempre que
            estáis en un proceso de selección, estáis dedicando vuestro tiempo y
            esfuerzo y eso, siempre merece respeto. Cuando os rechacen, exigid
            los motivos y si pasado un tiempo prudencial no tenéis noticias de
            la empresa, llamad y pedid explicaciones.
          </Text>
          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
            6 - Be curious!
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Read about the company and its employees!<br></br>
            <br></br>
            “La curiosidad mato al gato”. Eso dice el refrán, pero en este caso,
            yo creo que es todo lo contrario. Sed curiosos, investigad mucho
            sobre la empresa a la que estáis aplicando. En la red se puede
            encontrar mucha información y existen portales como Glassdoor donde
            podéis buscar información sobre las empresas, también encontrareis
            opiniones de empleados y exempleados. Por otro lado, una verdad como
            un templo es que las empresas son un reflejo de sus empleados y es
            cierto. Mirad y cotillead quienes serían vuestros compañeros. Que
            experiencia tienen, si tienen repositorios públicos y de ser así,
            podéis bichear su código. Yo soy de los que se quiere rodear de
            gente buena, para aprender lo máximo posible de ellos, llamadme
            egoísta.
          </Text>
          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
            7 - Learning opportunities!
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Training paths, share-knowledge atmosphere!<br></br>
            <br></br>
            Tanto si es tu primera experiencia laboral, como si estas valorando
            un cambio, esto es algo muy a tener en cuenta. Cierto es que si
            estas empezando tu carrera profesional la formación y el aprendizaje
            debe ser tu mayor motivación. Estar rodeado de buenos profesionales,
            que la empresa apueste por la formación, que mentoricen a
            profesionales que se inician, que tenga una atmosfera colaborativa y
            de compartir conocimiento son cosas claves para tu crecimiento.
            Creedme, esto es mucho más valioso que 2K más en tu nómina.
          </Text>
          <Heading color={"#0f172a"} fontSize={"2xl"} align={"justify"}>
            8 - Have patience!
          </Heading>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            We are in a lucky market with lots of opportunities!<br></br>
            <br></br>
            Tened paciencia. No hagáis las cosas con precipitación. Somos muy
            afortunados de pertenecer a un sector con pleno empleo y eso tenemos
            que hacerlo jugar a nuestro favor. Embarcarse en un proyecto
            profesional nuevo, es una decisión muy importante, nada que se deba
            tomar a la ligera, pensad si es lo que necesitáis en ese momento,
            pensad si es el mejor sitio para vosotros y pensad si es donde
            queréis estar los próximos años. Si no estáis seguros, no deis el
            paso.
          </Text>

          <Text color={"#0f172a"} fontSize={"lg"} align={"justify"}>
            Si estáis buscando empleo, espero que estos consejos os sirvan para
            afrontarlo de una mejor manera. Si comenzáis en el sector y tenéis
            dudas o queréis cualquier tipo de aclaración o recomendación, no
            dudéis en contactarme, estoy 100% abierto a ayudar en lo que este en
            mi mano. Suerte, salud y sed felices.
          </Text>
        </Stack>

        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={4}
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
        >
          <Box>
            <a href={post.substrack_link} target={"new"}>
              <Button
                width={"100%"}
                height={"50px"}
                backgroundColor={"#000000"}
                color={"#ffffff"}
                rounded={0}
                rightIcon={<SiSubstack />}
                fontWeight={600}
                textTransform="uppercase"
                _hover={{ backgroundColor: "#f10965" }}
              >
                suscribete
              </Button>
            </a>
          </Box>
          <Box>
            <a href={post.share_twitter} target={"new"}>
              <Button
                width={"100%"}
                height={"50px"}
                backgroundColor={"#000000"}
                color={"#ffffff"}
                rounded={0}
                rightIcon={<FaTwitter />}
                fontWeight={600}
                textTransform="uppercase"
                _hover={{ backgroundColor: "#f10965" }}
              >
                Share
              </Button>
            </a>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 8, md: 10 }}
          marginTop={{ base: "100px", md: "100px" }}
          marginBottom={{ base: "220px", md: "220px" }}
          marginLeft={{ base: "5%", md: "20%" }}
          marginRight={{ base: "5%", md: "20%" }}
        >
          <Stack spacing={4}>
            <Heading
              className="latest-post-heading"
              marginBottom={{ base: "15vw", md: "5vw" }}
              marginTop={{ base: "0vw", md: "3vw" }}
              fontSize={{ base: "10vh", md: "12vh" }}
              lineHeight={{ base: "80%", md: "100%" }}
            >
              Posts<br></br>similares<span style={{ color: "#f10965" }}>.</span>
            </Heading>
          </Stack>
          <VStack spacing={4} align="stretch">
            <a href={post.related_post_left.href} className="card-zoomer">
              <Card
                borderRadius="0"
                boxShadow={"xl"}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Stack>
                  <CardBody>
                    <Heading size="md">{post.related_post_left.title}</Heading>
                    <Text py="2">{post.related_post_left.spoiler}</Text>
                  </CardBody>
                </Stack>
              </Card>
            </a>
            <a href={post.related_post_right.href} className="card-zoomer">
              <Card
                borderRadius="0"
                boxShadow={"xl"}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Stack>
                  <CardBody>
                    <Heading size="md">{post.related_post_right.title}</Heading>

                    <Text py="2">{post.related_post_right.spoiler}</Text>
                  </CardBody>
                </Stack>
              </Card>
            </a>
          </VStack>
        </SimpleGrid>
      </Container>

      <Footer />
    </Fragment>
  );
}
