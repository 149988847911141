import { Heading, Box, Text, Stack, SimpleGrid } from "@chakra-ui/react";

export function WhatIdo() {
  return (
    <Box className="my-history-box" marginTop={{ base: "0vw", md: "1vw" }}>
      &nbsp;
      <Heading
        className="what-i-do-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "15vw", md: "5vw" }}
        fontSize={{ base: "10vh", md: "12vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        Qué hago<span style={{ color: "#66C7F4" }}>?</span>
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 4 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "220px", md: "220px" }}
        marginLeft={{ base: "5%", md: "5%" }}
        marginRight={{ base: "5%", md: "5%" }}
      >
        <Stack spacing={4}>
          <Text className="hero-skills-item">#development</Text>
          <Text className="hero-skills-item-desc">
            He pasado más de 15 años desarrollando software y liderando equipos
            de desarrollo. Creando una plataforma escalable y robusta, usando
            siempre buenas prácticas y enfocado en la entrega de constante de
            valor.
          </Text>
        </Stack>

        <Stack spacing={4}>
          <Text className="hero-skills-item">#team</Text>
          <Text className="hero-skills-item-desc">
            Liderando equipos de desarrollo, me di cuenta de que me apasionaba.
            Mentorizar y ayudar a crecer a la gente es algo increíble. Construir
            equipos equilibrados y autónomos es algo complejo.
          </Text>
        </Stack>
        <Stack spacing={4}>
          <Text className="hero-skills-item">#recruitment</Text>
          <Text className="hero-skills-item-desc">
            Contratar no es sencillo y perfiles técnicos, menos aún. Tienes
            miedo a equivocarte y muchas veces no sabes por donde empezar. Yo
            ayudé a una fintech a duplicar el equipo de desarrollo en un año,
            pasando de 35 personas a más de 70. Me apasiono y ahora estoy
            cambiando la forma de contratar perfiles técnicos desde Manfred.
          </Text>
        </Stack>
        <Stack spacing={4}>
          <Text className="hero-skills-item">#consultancy</Text>
          <Text className="hero-skills-item-desc">
            Si ponemos todo eso en una coctelera, podéis imaginar las mil y una
            experiencias vividas. Ahora mismo ayudo a empresas a identificar
            necesidades organizativas o de crecimiento, proponerles un plan de
            acción y acompañarlos en el camino.
          </Text>
        </Stack>
      </SimpleGrid>
    </Box>
  );
}
