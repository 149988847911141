import { Heading, Box, Text, Stack, SimpleGrid } from "@chakra-ui/react";

export function AcademyFormats() {
  return (
    <Box className="my-history-box" marginTop={{ base: "0vw", md: "1vw" }}>
      &nbsp;
      <Heading
        className="what-i-do-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "15vw", md: "5vw" }}
        fontSize={{ base: "8vh", md: "12vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        El formato<span style={{ color: "#C9E500" }}>.</span>
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 1 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "5vh", md: "10vh" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Stack spacing={4}>
          <Text>
            Es un taller pensado y diseñado para ser muy práctico y del que los
            alumnos se llevarán acciones que puedan poner en práctica desde el
            día siguiente. Habra dos sesiones con invitados; una en la que
            hablaremos con candidatos/as y contarán que red flags identifican en
            una oferta de empleo, como les gusta que sea el proceso completo… y
            otra sesión en la que personas que han contratado mucho y creado
            equipos para sus empresa nos contaran errores cometidos, buenas
            prácticas…
          </Text>
        </Stack>
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "20vh", md: "27vh" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Stack spacing={4}>
          <Text className="academy-format-item">abierto</Text>
          <Text>
            Pensado para que cualquier persona se pueda apuntar y disfrutar del
            taller. Un formato en el que participaras con profesionales de
            diferentes empresas y podrás escuchar opiniones y problemáticas que
            te nutrirán. Se realizará una edición cada 3 meses, teniendo cada
            edición un mínimo de asistentes de 10 personas y un máximo de 20.
          </Text>
          <Text>Precio por empresa con un máximo de 15 asistentes.</Text>
          <Text>
            Con una duración de 10 horas. Se realizara en fines de semana, 5
            horas el sábado y 5 horas el domingo.
          </Text>

          <Text className="academy-pricing">
            400<span style={{ color: "#000000" }}> € + IVA</span>
          </Text>
        </Stack>

        <Stack spacing={4}>
          <Text className="academy-format-item">para empresas</Text>
          <Text>
            Diseñado para que puedan asistir diferentes personas con diferentes
            roles de una <b>misma</b> empresa y salgan alineados en como
            realizar la contratación de perfiles técnicos para su empresa.
            Idealmente, este formato se realizará en sesiones de 2 horas durante
            5 días que no tiene por que ser consecutivos
          </Text>
          <Text>Precio por empresa con un máximo de 15 asistentes.</Text>
          <Text>
            Se puede personalizar para la necesidad de cada empresa. Para más
            información escribir a{" "}
            <b>
              <a href="mailto:yo@leonardopoza.com">yo@leonardopoza.com</a>
            </b>
          </Text>

          <Text className="academy-pricing">
            5.000 <span style={{ color: "#000000" }}> € + IVA</span>
          </Text>
        </Stack>
      </SimpleGrid>
    </Box>
  );
}
